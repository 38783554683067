import React, { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import Input                                                       from "@cComponents/input";
import T                                                           from "@cBehaviour/i18n";
import Display                                                     from "@common/components/displayIf";
import Highlight                                                   from "@cComponents/highlight";
import "./search.css"

interface FakeAutoCompleteProps {
  onEdit: () => void;
  onDelete: () => void;
  children: React.ReactNode;
}

interface FakeAutoCompleteProps {
  onEdit: () => void;
  onDelete: () => void;
}

const FakeAutoComplete: FunctionComponent<PropsWithChildren<FakeAutoCompleteProps>> = ({ onEdit, onDelete, children }) => {
  const stopPropagationAndTransfertOnDelete = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onDelete();
  }, [onDelete]);

  const stopPropagationAndTransfertOnEdit = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onEdit();
  }, [onEdit]);

  return (
    <div className="bs-assignmentsAndIssues-search-fakeAutoComplete" onClick={ stopPropagationAndTransfertOnEdit }>
      <div className="bs-assignmentsAndIssues-search-fakeAutoComplete-content">
        <span>
          { children }
        </span>
      </div>
      <div>
        <span className="fa fa-times" onClick={ stopPropagationAndTransfertOnDelete } />
      </div>
    </div>
  );
}

const SearchDisplay = ({
  value,
  searched,
  onDelete,
  onEdit,
  onChanged,
  onKeyUped,
  search,
  fluid,
  stringifySearchQuery
}) => {

  const getSearchIcon= (value) => {
    if (!value || !value.type) return "";
    switch(value.type) {
      case "issue": return "exclamation-circle";
      case "building": return "building";
      case "equipmentConcerned":
      case "equipmentUsed": return "wrench";
      case "userpro": return "institution";
      case "citizen": return "user";

    }
  }
  

  const getSearchPrefix = (value)=>{
    if (!value || !value.type) return null
    switch(value.type){
      case "equipmentConcerned": return <T>issue_filter_search_concerned</T>;
      case "equipmentUsed":      return <T>issue_filter_search_used</T>;
      default: return null;
    }
  }

  return (
    <>
      <Highlight.Controller type={ value?.type } highlight={ value?.type === 'contains' ? value.value : null } />
      <Display.If condition={ value && value.type }>
        <Display.Then>
        {() => (
            <FakeAutoComplete onEdit={onEdit} onDelete={onDelete}>
            <div>
              <span className={`fa fa-${getSearchIcon(value)}`} />
              &nbsp;{stringifySearchQuery}
            </div>
          </FakeAutoComplete>
        )}
        </Display.Then>
        <Display.Else>
          <Input.Autocomplete
            key={searched}
            delay={500}
            search={search}
            searched={searched}
            value={value || ""}
            onChange={onChanged}
            onKeyUp={onKeyUped}
            fluid={fluid}
            focusOnMount
          >
            <Input.Autocomplete.Placeholder>
              <T>freesearch</T>
            </Input.Autocomplete.Placeholder>
            <Input.Autocomplete.Item>
              {(value, label) => (
                <div>
                  <span className={`fa fa-${getSearchIcon(value)}`} />
                  &nbsp;
                  {getSearchPrefix(value)}{label}
                </div>
              )}
            </Input.Autocomplete.Item>
          </Input.Autocomplete>
        </Display.Else>
      </Display.If>
    </>
  );
};

export default SearchDisplay;

import Key from "@universal/lib/key";
import CacheService from "@universal/services/cacheService";
import CurrentTenantService from "@universal/services/currentTenant";
import Assignment from "@universal/types/business/Assignment";
import Building from "@universal/types/business/Building";
import Category from "@universal/types/business/Category";
import Contact from "@universal/types/business/Contact";
import Equipment from "@universal/types/business/Equipment";
import File from "@universal/types/business/File";
import Issue from "@universal/types/business/Issue";
import Recurrence from "@universal/types/business/Recurrence";
import Supply from "@universal/types/business/Supply";
import Team from "@universal/types/business/Team";
import Tenant from "@universal/types/business/Tenant";
import User from "@universal/types/business/User";
import Valorization from "@universal/types/business/Valorization";

function getCacheService(){
  const cacheToInitialize = [
    { model: "Category", api: "categories", key: Key.create<Category>("_id") },
    { model: "Building", api: "buildings", key: Key.create<Building>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Team", api: "teams", key: Key.create<Team>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Equipment", api: "equipments", key: Key.create<Equipment>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Contact", api: "contacts", key: Key.create<Contact>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Supply", api: "supplies", key: Key.create<Supply>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Recurrence", api: "recurrences", key: Key.create<Recurrence>("_id"), getQuery: (currentTenantService: CurrentTenantService) => ({ tenant: currentTenantService.currentId }) },
    { model: "Tenant", api: "tenants", key: Key.create<Tenant>("_id") },
    { model: "Issue", api: "issues", key: Key.create<Issue>("_id") },
    { model: "Assignment", api: "assignments", key: Key.create<Assignment>("_id") },
    { model: "File", api: "files", key: Key.create<File>("_id") },
    { model: "Valorization", api: "valorizations", key: Key.create<Valorization>("_id") },
    { model: "User", api: "users", key: Key.create<User>("_id") },
  ];
  return new CacheService(cacheToInitialize)
}

export default getCacheService;
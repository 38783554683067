import React, { FunctionComponent } from 'react';

import { ITest } from '../library/test';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import Display from '@universal/components/displayIf';
import Step from './step';
import State from './state';

import './test.css';

type TestPorps = {
  test: ITest;
}

const Test: FunctionComponent<TestPorps> = ({ test }) => {
  const [display,,, toggle] = useOpenCloseToggle(false);

  return (
    <div className="bs-test">
      <div className="bs-test-header">
        <div className="bs-test" onClick={ toggle }>
          <span className={ `fa fa-caret-${ display ? "up" : "down" }` } />
        </div>
        <div>{ test.description }</div>
        <div><State state={ test } /></div>
      </div>
      <Display.If condition={display}>
        <div className="bs-test-content">
        {
          test.getSteps().map((step, index) => (
            <Step key={index} step={step} />
          ))
        }
        </div>
      </Display.If>
    </div>
  );
};

export default Test;
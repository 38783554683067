import React from "react";
import Display from '@cComponents/displayIf';
import Input from '@cComponents/old/input';
import NInput from '@cComponents/input';


import './selectCurrentTenant.css';
import useService from "@universal/behaviour/hooks/useService";
import useHelper from "@universal/behaviour/hooks/useHelper";
import useModel from "@universal/behaviour/data/hooks/useModel";
import { combinate } from "@universal/lib/query";

export default {
  Unlimited: ({ }) => {
    const currentTenant = useService('currentTenant');
    const i18n = useService('i18n');
    const tenantHelper = useHelper('tenant');
    const repository = useModel("Tenant").repository;
    
    const search = React.useCallback(async (value) => {
      const startWithQuery = combinate("$or",
        { [i18n.queryProperty("name")]: { '$regex': `^${value}`, '$options': 'i' } },
        { 'informations.siren':  { '$regex': `^${ value }`, '$options': 'i' } },
        { 'informations.postalCode': { '$regex': `^${value  }`, '$options': 'i' } },
      );
      let results = await repository.find(startWithQuery, { [i18n.queryProperty("name")]: 1 }, 0, 20);

      if(results.length < 20) {
        const query = combinate("$and",
          combinate("$or",
            { [i18n.queryProperty("name")]: { '$regex': `${value}`, '$options': 'i' } },
            { 'informations.siren':  { '$regex': `${ value }`, '$options': 'i' } },
            { 'informations.postalCode': { '$regex': `${value  }`, '$options': 'i' } },
          ),
          combinate("$and",
            { [i18n.queryProperty("name")]: { $not: { '$regex': `^${value}`, '$options': 'i' } }},
            { 'informations.siren':  { $not: { '$regex': `^${ value }`, '$options': 'i' } }},
            { 'informations.postalCode': { $not: { '$regex': `^${value  }`, '$options': 'i' } }},
          )
        );
        const otherResults = await repository.find(query, { [i18n.queryProperty("name")]: 1 }, 0, 20 - results.length);
        results = results.concat(otherResults);
      }

      return results.map(tenant => {
        return { 
          value: tenant._id,
          label: tenantHelper.getTenantLabel(tenant)
        };
      });
    }, [repository, i18n]);

    const setCurrentTenant = React.useCallback((input) => {
      currentTenant.currentId = input.value;
    }, [currentTenant]);

    const clearCurrentTenant = React.useCallback(() => {
      currentTenant.currentId = null;
    }, [currentTenant]);

    return (
      <div className="bs-selectTenant">
        <Display.If condition={currentTenant.isSelected()}>
          <Display.Then>
            <div className="bs-selectedTenant">
              <span>{ tenantHelper.currentTenantLabel }</span>
              <span className="bs-close fa fa-close" onClick={ clearCurrentTenant } />
            </div>
          </Display.Then>
          <Display.Else>
            <div className="bs-selectCurrentTenant-autocomplete">
              <Input.Autocomplete
                onChange={ setCurrentTenant }
                search={ search }
              />
            </div>
          </Display.Else>
        </Display.If>
      </div>
    );
  },
  Limited: ({ }) => {
    const currentTenant = useService('currentTenant');
    const i18n = useService('i18n');
    const tenantHelper = useHelper('tenant');

    return (
      <div className="bs-selectTenant">
        <Input.Select onChange={(input) => { currentTenant.currentId = input.value; }} value={currentTenant.currentId}>
          {currentTenant.allTenants.sort((t1, t2) => i18n.translate(t1.name).localeCompare(i18n.translate(t2.name))).map(tenant => (
            <Input.Select.Value key={`input_selectCurrentTenant_${tenant._id}`} value={tenant._id} >
              <span>{tenantHelper.getTenantLabel(tenant)}</span>
            </Input.Select.Value>
          ))}
        </Input.Select>
      </div>
    );
  }
}
import SortRule from '@universal/types/technic/Sort';
import Key from '../key';
import Sorter from '../sorter';
import Query from '@universal/types/technic/Query';
import Filter from '@common/lib/filter';
import ICollection from './iCollection';

const toArray = <Type>(iterable: Iterable<Type>): Array<Type> => {
  const result: Array<Type> = [];
  for (const item of iterable) {
    result.push(item);
  }
  return result;
};

class CollectionIterator<Type> implements Iterator<Type, undefined> {
  private index: number;
  private collection: ICollection<Type>;
  private items: Array<Type>;

  constructor(collection: ICollection<Type>) {
    this.index = -1;
    this.collection = collection;
    this.items = this.collection.toArray();
  }
  
  next(): IteratorResult<Type, undefined> {
    if(++this.index < this.items.length) {
      return {
        done: false,
        value: this.items[this.index]
      };
    }
    return {
      done: true,
      value: undefined
    };
  }

  return(value?: any): IteratorResult<Type, undefined> {
    this.index = this.items.length;
    return {
      done: true,
      value: value || undefined
    };
  }
}

class Collection<Type> implements ICollection<Type> {
  private hashToItem: Map<string, Type>;
  protected items: Array<Type>;
  protected key: Key<Type>;
  protected _sorter: Sorter<Type>;

  constructor(key: Key<Type>) {
    this.hashToItem = new Map();
    this.items = [];
    this.key = key;

    this._sorter = Sorter.create<Type>(key.sortRule);
  }

  get sorter(): Sorter<Type> {
    return this._sorter.clone();
  }

  clear(): void {
    this.hashToItem.clear();
    this.items = [];
  }

  [Symbol.iterator](): Iterator<Type, undefined, any> {
    return new CollectionIterator(this);
  }

  has(item: Type): boolean {
    return this.hashToItem.has(this.key.hash(item));
  }

  isInBound(item: Type): boolean {
    if(this.items.length === 0) {
      return false;
    }
    const lastItem = this.items[this.items.length - 1];
    return this._sorter.compare(item, lastItem) < 0;
  }
  

  private addLocal(item: Type) {
    this.hashToItem.set(this.key.hash(item), item);
  }

  add(item: Type) {
    this.addLocal(item);
    this.items = toArray(this.hashToItem.values()).sort(this._sorter.compare);
  }

  addMany(items: Iterable<Type>){
    for (const item of items) {
      this.addLocal(item);
    }
    this.items = toArray(this.hashToItem.values()).sort(this._sorter.compare);
  }

  private dropLocal(item: Type) {
    this.hashToItem.delete(this.key.hash(item));
  }

  drop(item: Type) {
    this.dropLocal(item);
    this.items = toArray(this.hashToItem.values()).sort(this._sorter.compare);
  }

  dropMany(items: Iterable<Type>) {
    for (const item of items) {
      this.dropLocal(item);
    }
    this.items = toArray(this.hashToItem.values()).sort(this._sorter.compare);
  }

  toArray(): Array<Type> {
    return this.items.slice();
  }

  get(object: Partial<Type>): Type {
    const hash = this.key.hash(object);
    if(!this.hashToItem.has(hash)){
      throw new Error('Item not found');
    }
    return this.hashToItem.get(hash) as Type;
  }

  forEach(callback: (item: Type) => void) {
    this.items.forEach(callback);
  }

  map<NewType>(callback: (item: Type) => NewType): Array<NewType> {
    return this.items.map(callback);
  }

  get length(): number {
    return this.items.length;
  }

  find(query: Query<Type> = {}, sortRule: SortRule<Type> | Sorter<Type>): ICollection<Type> {
    const filter = Filter.factory<Type>(query);
    const collection = new Collection<Type>(this.key);
    collection.addMany(this.items.filter(filter.match));
    return collection as Collection<Type>;
  }

  slice(offset: number, limit: number): Array<Type> {
    return this.items.slice(offset, offset + limit);
  }
}

export default Collection;
import React, { FunctionComponent } from 'react';
import Form from '@uBehaviour/form';
import Field from '@cComponents/field';
import Button from '@cComponents/button';
import Input from '@cComponents/input';
import T from '@uBehaviour/i18n';
import useService from '@universal/behaviour/hooks/useService';
import ApiService from '@universal/services/api';

import './form.css';
import dayjs from 'dayjs';

type FormSimple = typeof Form.Simple;

type Configuration = Record<string, { value: string, updatedAt: string}>;

const addDate = (date: string) => ` (${ dayjs(date).format('DD/MM/YYYY HH:mm') })`;

const ConfigurationForm: FunctionComponent = ({}) => { 
  const formRef = React.createRef<FormSimple>();
  const [config, setConfig] = React.useState<Configuration | null>(null);
  const api = useService<ApiService>("api");
  
  React.useEffect(() => {
    if (!config) {
      api.service("configuration", "getForForm").execute().then((config: Configuration) => setConfig(config));
    }
  });

  const submit = React.useCallback(async (form: FormSimple, config: Configuration) => {
    const serverConfig = await api.service("configuration", "put").execute(config);
    setConfig(serverConfig);
  }, [api]);

  const formValue = React.useMemo(() => config && Object.keys(config).reduce<Record<string, string>>((acc, key) => {
    acc[key] = config[key].value;
    return acc;
  }, {}), [config]);

  return config && (
    <div className="bs-form-configuration-container">
      <Field.ShortLabelContext prefix="configuration_form">
        <Form.Simple 
          ref={formRef}
          value={ formValue }
          submit={ submit }
        >
          <Field.Short name="adminMobileVersion" labelAdd={ addDate(config.adminMobileVersion.updatedAt) } required>
            <Input.Text />
          </Field.Short>
          <Field.Short name="citizenMobileVersion" labelAdd={ addDate(config.citizenMobileVersion.updatedAt) } required>
            <Input.Text />
          </Field.Short>
          <Field.Short name="cguVersion" labelAdd={ addDate(config.cguVersion.updatedAt) } required>
            <Input.Text />
          </Field.Short>
        </Form.Simple>
      </Field.ShortLabelContext>
      <div>
        <Button.Text onClick={() => formRef.current.submit()}><T>save</T></Button.Text>
      </div>
    </div>    
  )
};

export default ConfigurationForm;
import React from 'react';
import Set from './components/set';
import useApplication from "@universal/hooks/useApplication";
import buildTest from './case';
import { TestSet } from './library/test';
import ScrollBar from '@common/components/scrollBar/scrollBar';


const Screen = () => {

  const application = useApplication();
  const [testSet, setTestSet] = React.useState<TestSet | null>(null);

  React.useEffect(() => { buildTest(application).then(setTestSet) }, [application]);

  return (
    <ScrollBar>
      <div style={{ padding: '10px'}}>
        <Set testSet={ testSet } />
      </div>
    </ScrollBar>
  );
}

export default Screen;
import Application from "@universal/lib/application";
import ApiService from "@universal/services/api";
import Tenant from "@universal/types/business/Tenant";
import { LoggableUser, UserTechnical } from "@universal/types/business/User";


export default (tenantQuery: any) => async (appTest: Application) => {
  const pageSize = 20;
  let offset = 0;
  let user = null;
  let tenant = null;
  while(!user) {
    const users = await appTest.getService<ApiService>("api").service("users", "get").execute({
      discriminator: "technical",
      application: "jvs-pp"
    }, { _id: -1 }, offset, pageSize) as UserTechnical[];

    if(!users.length){
      throw "Aucun utilisateur technique trouvée";
    }

    for(const u of users){
      const tenants = await appTest.getService<ApiService>("api").service("tenants", "get").execute({ $and: [{
        _id: u.tenant
      }, tenantQuery] }) as Tenant[];

      if(tenants.length){
        user = u as LoggableUser;
        tenant = tenants[0];
        break;
      }
    }

    offset += pageSize;
  }

  if(!user || !tenant){
    throw "Aucun utilisateur technique trouvé pour une structure répondant aux critères";
  }

  return { user, tenant };
};

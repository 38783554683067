import React, { ChangeEvent, FunctionComponent } from "react";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import Team from "@universal/types/business/Team";
import { UserDiscriminators, UserPro } from "@universal/types/business/User";
import T from '@uBehaviour/i18n';
import Avatar from '@cFeatures/user/avatar';
import ObjectId from "@universal/types/technic/ObjectId";
import useI18n from "@universal/behaviour/hooks/useI18n";
import usePager from "@universal/behaviour/data/hooks/usePager";
import useCurrentTenant from "@universal/behaviour/hooks/useCurrentTenant";

import "./select.css";
import Button from "@common/components/button";
import ScrollBar from "@common/components/scrollBar/scrollBar";
import { Grid } from "semantic-ui-react";
import Query from "@universal/types/technic/Query";

const firstLetter = (str: string) => str.substr(0, 1).toUpperCase();

const agentDisplayName = (agent: UserPro) => `${firstLetter(agent.firstname)}. ${agent.lastname.toUpperCase()}`;

const TeamItemFactory = ({ team, toggleSelection }) => {
  if (!team){
    return <WithoutTeamItem />;
  }
  return <TeamItem team={ team } toggleSelection={ toggleSelection }/>
}
const WithoutTeamItem = ()=>{
  return (
    <div className={ `bs-select-teamsagents-team not-selectable`}>
      <span className="fa fa-users" />
      <div><T>planning_team_withoutTeam</T></div>
    </div>
    )
}

const TeamItem = ({ team, toggleSelection }) => {
  const toggleTeamSelection = React.useCallback(() => toggleSelection(team.data), [team, toggleSelection]);
    return (
      <div className={ `bs-select-teamsagents-team selectable ${ team.selected ? " selected" : ""}` } onClick={ toggleTeamSelection }>
        <span className="fa fa-users" />
        <div>{ team.data.name }</div>
      </div>
    );
}

const AgentItem = ({ agent, toggleSelection }) => {
  const toggleAgentSelection = React.useCallback(() => toggleSelection(agent.data), [agent, toggleSelection]);
  return (
    <div className={ `bs-select-teamsagents-agent${ agent.selected ? " selected" : ""}` } onClick={ toggleAgentSelection }>
      <Avatar user={ agent.data } />
      <div>{ agentDisplayName(agent.data) }</div>
    </div>
  );
};

const TeamLine = ({ team, agents, toggleTeamSelection, toggleAgentSelection }) =>  (
  <div className="bs-select-teamsagents-teamblock" >
    <div>{  <TeamItemFactory team={ team } toggleSelection={ toggleTeamSelection }/>} </div>
    <div>
    {
      agents.map(agent => (<AgentItem key={ agent.data._id } agent={ agent } toggleSelection={ toggleAgentSelection }/>))
    }
    </div>
  </div>
);

interface SelectedItemState<T>{
  selected: boolean;
  data: T;
}
interface SelectedState {
  teams: SelectedItemState<BusinessEntity<Team>>[];
  agents: SelectedItemState<BusinessEntity<UserPro>>[];
}
export interface Selected {
  team: BusinessEntity<Team>[];
  agents: BusinessEntity<UserPro>[];
}
interface SelectBaseProps {
  teams: BusinessEntity<Team>[];
  agents: BusinessEntity<UserPro>[];
  selected: Selected;
  onChange: (selected: Selected) => void;
  stopSelection: () => void;
}

const userCompare = (a1: SelectedItemState<BusinessEntity<UserPro>>, a2: SelectedItemState<BusinessEntity<UserPro>>): number => agentDisplayName(a1.data).localeCompare(agentDisplayName(a2.data));

export const SelectBase: FunctionComponent<SelectBaseProps> = ({ teams, agents, selected, onChange, stopSelection }) => {
  const [selectedState, setSelectedState] = React.useState<SelectedState>({ teams: [], agents: [] });
  
  React.useEffect(() => {
    setSelectedState({
      teams: teams.map(team => {
        return {
          selected: !!selected.team.find(t => t._id === team._id),
          data: team
        };
      }),
      agents: agents.map(agent => {
        return {
          selected: !!selected.agents.find(a => a._id === agent._id),
          data: agent
        };
      })
    });
  }, [teams, agents, selected]);

  const toggleTeamSelection = React.useCallback((team: Team) => {
    setSelectedState(selectedState => {
      selectedState.agents.forEach(agent => { agent.selected = false; });
      selectedState.teams.forEach(lTeam => { lTeam.selected = lTeam.data._id === team._id ? !lTeam.selected : false; });
      return { ...selectedState };
    });
  }, [setSelectedState]);

  const toggleAgentSelection = React.useCallback((agent: UserPro) => {
    setSelectedState(selectedState => {
      selectedState.teams.forEach(team => { team.selected = false; });
      const lAgent = selectedState.agents.find(lAgent => lAgent.data._id === agent._id);
      if(lAgent){
        lAgent.selected = !lAgent.selected;
      }
      return { ...selectedState };
    });
  }, [setSelectedState]);

  const validSelection = React.useCallback(() => {
    onChange({ 
      team: selectedState.teams.filter(t => t.selected).map(t => t.data),
      agents: selectedState.agents.filter(a => a.selected).map(a => a.data)
    });
    stopSelection();
  }, [onChange, stopSelection, selectedState]);

  const inputSearch = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    inputSearch.current.focus();
  }, []);

  const [filter, setFilter] = React.useState(/.*/);
  const changeFilter = React.useCallback(({ target: { value: filter }}: ChangeEvent<HTMLInputElement>) => {
    if(filter){
      setFilter(new RegExp(`.*${filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}.*`, 'i'));
    }else{
      setFilter(/.*/);
    }
  }, [setFilter]);

  const teamsAndAgents = React.useMemo<{ team: SelectedItemState<BusinessEntity<Team>>|null, agents: SelectedItemState<BusinessEntity<UserPro>>[]}[]>(() => {
    const loadedTeamIds = selectedState.teams.reduce((dic, sTeam) => {
      dic.set(sTeam.data._id, true);
      return dic;
    }, new Map<ObjectId, true>());
    
    const dicTeamToAgents = selectedState.agents.reduce((dic, agent) => {
      if(filter.test(agent.data.fullname)){
        const teamId = agent.data.toPlainText().team as ObjectId;
        const usedTeamId = !teamId ? null : (loadedTeamIds.has(teamId) ? teamId : null);
        if(!dic.has(usedTeamId)){
          dic.set(usedTeamId, []);
        }
        dic.get(usedTeamId).push(agent);
      }
      return dic;
    }, new Map<ObjectId|null, SelectedItemState<BusinessEntity<UserPro>>[]>());

    const teamsAndAgents = selectedState.teams.filter(t => {
      return dicTeamToAgents.has(t.data._id) || filter.test(t.data.name);
    }).sort((t1, t2) => t1.data.name.localeCompare(t2.data.name)).map(t => {
      return {
        team: t,
        agents: dicTeamToAgents.has(t.data._id) ? dicTeamToAgents.get(t.data._id).sort(userCompare) : []
      };
    });
    if(!dicTeamToAgents.has(null)){
      return teamsAndAgents;
    }
    return teamsAndAgents.concat({ team: null, agents: dicTeamToAgents.get(null).sort(userCompare) });
  }, [selectedState, filter]);
  
  const inputPlaceHolder = useI18n('teamsAndAgents_select_search');

  return (
    <div className="bs-select-teamsagents">
      <div>
        <div><T>teamsAndAgents_select_title</T></div>
        <div>
          <div>
            <span  className="bs-select-teamsagents-search">
              <input
                ref={ inputSearch }
                placeholder={ inputPlaceHolder }
                type="text"
                onChange={ changeFilter }
              />
              <span className="fa fa-search"/>
            </span>
          </div>
        </div>
      </div>
      <ScrollBar>
      { 
        teamsAndAgents.map(({ team, agents }) => (
          <TeamLine
            key={team ? team.data._id : "no-team"}
            team={ team }
            agents={ agents }
            toggleTeamSelection={ toggleTeamSelection }
            toggleAgentSelection={ toggleAgentSelection }
          />
        ))
      }
      </ScrollBar>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={ 12 } />
            <Grid.Column width={4} className='bs-select-teamsagents-footer-control'>
              <Button.Text color="#a09696" onClick={ stopSelection }>
                <T>teamsAndAgents_select_stopSelection</T>
              </Button.Text>
              <Button.Text onClick={ validSelection }>
                <T>teamsAndAgents_select_validSelection</T>
              </Button.Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};
interface SelectProps {
  selected: Selected;
  onChange: (selected: Selected) => void;
  stopSelection: () => void;
  queryAgent?: null | Query<UserPro>;
  queryTeam?: null | Query<Team>;
};
const Select: FunctionComponent<SelectProps> = ({ selected, onChange, stopSelection, queryAgent = null, queryTeam = null }) => {

  const tenantService = useCurrentTenant();
  const tenant = tenantService.currentId;

  const selectedAgentIds = selected.agents.map(a => a._id);
  if (!queryAgent) {
    queryAgent = {
      $or: [
        { 
          discriminator: UserDiscriminators.pro, 
          tenants: { 
            $elemMatch: { tenant, roles: { $in: ["agent"] }, disabled: false } 
          } 
        }, 
        { 
          _id: { $in: selectedAgentIds },
        }
      ]
    };
  }
  const { datas: agents } = usePager<UserPro>({ model: "User", query: queryAgent, sort: { fullname: -1 }, load: { avatar: true }, loadAll: true });
  if(!queryTeam) {
    const selectedTeamIds = selected.team.map(t => t._id); 
    queryTeam = {
      $or: [
        { tenant, disabled: false }, 
        { _id: { $in: selectedTeamIds } } 
      ]
    };
  }
  const { datas: teams } = usePager<Team>({ model: "Team", query: queryTeam, sort: { name: -1 }, loadAll: true });
  return <SelectBase selected={ selected } onChange={ onChange } stopSelection={ stopSelection } agents={ agents } teams={ teams }/>
};

export default Select;
import Display from '@common/components/displayIf';
import Field from '@common/components/field';
import Input from '@common/components/input';
import TenantComponent from '@entities/tenant';
import Form from '@universal/behaviour/form';
import useService from '@universal/behaviour/hooks/useService';
import T from '@universal/behaviour/i18n';
import generatePassword, { generateFullname, generateUsername } from '@universal/lib/password';
import ApiService from '@universal/services/api';
import CurrentTenantService from '@universal/services/currentTenant';
import I18nService from '@universal/services/i18n';
import RepositoryService from '@universal/services/repository';
import Tenant from '@universal/types/business/Tenant';
import React, { ForwardRefRenderFunction, FunctionComponent } from 'react';
import Copy from '@cComponents/copy';
import HiddenString from '@cComponents/hiddenString';
import Button from '@common/components/button';
import Section from '@common/components/section';
import _ from 'lodash';

import './technicalUser.css';
import { UserTechnical } from '@universal/types/business/User';

const textify = (value) => (<T>{ value.name }</T>);

interface InputProps {
  value: string;
  set: (value: string) => void;
  clear: () => void;
}

const InputTenant: FunctionComponent<InputProps> = ({ value, set, clear }) => {
  const i18n = useService<I18nService>("i18n", ['onServiceUpdated']);

  const extractFirstOrClear = React.useCallback((values) => {
    if(!values.length){
      return clear();
    }
    set(values[0]._id);
  }, [set, clear]);

  const arrayValue = React.useMemo(() => {
    if(!value) return [];
    return [{ _id: value }];
  }, [value]);

  return (
    <Input.Selectable 
      withFilter 
      model="Tenant"
      query={{ "settings.commercialOffer": { $ne: null }}}
      onChange={ extractFirstOrClear }
      value={ arrayValue }
      limit={1} sort={{ [i18n.queryProperty("name")]: 1 }}
      textify={ textify }
      load={ TenantComponent.Item.Load }
      filterQuery={ (value: string) => ({ [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' } }) }
    >
      <TenantComponent.Item />
    </Input.Selectable>
  );
}

const InputApplication: FunctionComponent<InputProps> = ({ value, set, clear }) => {  
  return (
    <Input.Radio.Btn value={ value } onChange={ set } inline>
      <Input.Radio.Value value="jvs-ac"><span><T>user_technicalUser_input_application_ac</T></span></Input.Radio.Value>
      <Input.Radio.Value value="jvs-pp"><span><T>user_technicalUser_input_application_pp</T></span></Input.Radio.Value>
      <Input.Radio.Value value="jvs-gs"><span>Gestion de salle JVS</span></Input.Radio.Value>
      <Input.Radio.Value value="tablet"><span>Tablette</span></Input.Radio.Value>
    </Input.Radio.Btn>
  );
}

const InputDefaultPublicIsssue: FunctionComponent<InputProps> = ({ value, set, clear }) => {  
  return (
    <Input.Radio.Btn value={ value } onChange={ set } inline>
      <Input.Radio.Value value={ true }><span><T>user_technicalUser_input_defaultPublicIssue_true</T></span></Input.Radio.Value>
      <Input.Radio.Value value={ false }><span><T>user_technicalUser_input_defaultPublicIssue_false</T></span></Input.Radio.Value>
    </Input.Radio.Btn>
  );
}

type FormSimple = typeof Form.Simple;

interface TechnicalUserFormProps {
  submit: (form: FormSimple, value: Object) => void
}

const TechnicalUserForm: ForwardRefRenderFunction<FormSimple, TechnicalUserFormProps> = React.forwardRef(({ submit }, ref) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const repository = useService<RepositoryService>("repository");
  
  const defaultValue = React.useMemo(() => ({
      discriminator: "technical",
      fullname: currentTenant.isSelected() ? generateFullname(currentTenant.current, "jvs-ac") : "",
      tenant: currentTenant.isSelected() ? currentTenant.currentId : null,
      disabled: false,
      application: "jvs-ac",
      createdIssueIsPublic: false,
      authentificationProviders: [{
        discriminator: "username",
        username: currentTenant.isSelected() ? generateUsername(currentTenant.current, "jvs-ac") : "",
        password: generatePassword(32)
      }]
    }), [currentTenant]);

  const updatedUsernameAndFullname = React.useCallback((form: FormSimple, value: any, diff: any) => {
    if(value.tenant && ["application", "tenant"].includes(diff[0].path.join("."))){
      repository.get("Tenant").repository.get([{ _id: value.tenant }]).then(([tenant]: [Tenant]) => {
        form.set("fullname", generateFullname(tenant, value.application));
        form.set("authentificationProviders.0.username", generateUsername(tenant, value.application));
      });
    }
    return value;
  }, [repository]);

  return (
    <Form.Simple default={ defaultValue } submit={ submit } onChange={ updatedUsernameAndFullname } ref={ ref }>
    {(ctx, value, errors, form, submit) => (
      <>
        <Field.Display>
          <Field.Label><T>user_technicalUser_tenant</T></Field.Label>
          <Field.Input>
            <Display.If condition={ currentTenant.isSelected() }>
              <Display.Then>
              {() => (
                <div>
                  <T>{currentTenant.current.name}</T>
                </div>
              )}
              </Display.Then>
              <Display.Else>
                <Form.Simple.InputAdapter name="tenant">
                {(value, set, clear) => (<InputTenant value={ value } set={ set } clear={ clear } /> )}
                </Form.Simple.InputAdapter>
              </Display.Else>
            </Display.If>
          </Field.Input>
        </Field.Display>
        <Field.Display>
          <Field.Label><T>user_technicalUser_application</T></Field.Label>
          <Field.Input>
            <Form.Simple.InputAdapter name="application">
            {(value, set, clear) => (<InputApplication value={ value } set={ set } clear={ clear } /> )}
            </Form.Simple.InputAdapter>
          </Field.Input>
        </Field.Display>
        <Display.If condition={ value.application !== "tablet" }>
          <Field.Display>
            <Field.Label><T>user_technicalUser_default_public_issue</T></Field.Label>
            <Field.Input>
              <Form.Simple.InputAdapter name="createdIssueIsPublic">
              {(value, set, clear) => (<InputDefaultPublicIsssue value={ value } set={ set } clear={ clear } /> )}
              </Form.Simple.InputAdapter>
            </Field.Input>
          </Field.Display>
        </Display.If>
      </>
    )}
    </Form.Simple>
  );
});


const DisplayUsernameAndPassword = ({ user }) => {
  return (
    <div>
      <Field.Display>
        <Field.Label><T>user_technicalUser_fullname</T></Field.Label>
        <Field.Input>{ user.fullname }</Field.Input>
      </Field.Display>
      <Field.Display>
        <Field.Label><T>user_technicalUser_username</T></Field.Label>
        <Field.Input>
          <div className='bs-users-technical-user-info'>
            <Copy value={ user.authentificationProviders[0].username }>{ (value) => <span>{ value }</span>}</Copy>
          </div>
        </Field.Input>
      </Field.Display>
      <Field.Display>
        <Field.Label><T>user_technicalUser_password</T></Field.Label>
        <Field.Input>
          <div className='bs-users-technical-user-info'>
            <Copy value={ user.authentificationProviders[0].password }>
            {(value) => (
              <HiddenString value={ value} />
            )}
            </Copy>
          </div>
        </Field.Input>
      </Field.Display>
    </div>
  );
};


interface DisplayTechnicalUserProps {
  data: UserTechnical;
  close: () => void
}

export const DisplayTechnicalUser: FunctionComponent<DisplayTechnicalUserProps> = ({ data: user, close }) => {
  const api = useService<ApiService>("api");
  const [password, setPassword] = React.useState<null | string>(null);
  const formRef= React.createRef<FormSimple>();
  
  const updatePassword = React.useCallback(() => {
    const password = generatePassword(32);
    api.service("users", "resetPassword").execute(user._id, password).then(() => {
      setPassword(password);
    });
  }, [user]);

  const submit = React.useCallback((form: FormSimple, value: any) => {
    if(value.application === "tablet"){
      value = {
        discriminator: "tablet",
        firstname: "Application",
        lastname: value.fullname,
        fullname: value.fullname,
        tenant: value.tenant,
        disabled: false,
        authentificationProviders: value.authentificationProviders
      }
    }
    return api.service("users", "put").execute(user._id, value).then(close)
  });

  const submitForm = React.useCallback(() => {
    formRef.current?.submit(true);
  }, [ formRef ]);

  if(user.discriminator === "tablet"){
    user = {
      application: "tablet",
      discriminator: "technical",
      fullname: user.fullname,
      tenant: user.tenant,
      disabled: false,
      authentificationProviders: user.authentificationProviders
    };
  }else {
    user = user.toPlainText();
  }

  return (
    <Form.Simple default={ user } submit={ submit } ref={ formRef }>
      <Section.Header>
        <Section.Header.Text><T>user_technicalUser_view_title</T></Section.Header.Text>
      </Section.Header>
      <Section.Content>
        <Field.Display>
          <Field.Label><T>user_technicalUser_fullname</T></Field.Label>
          <Field.Input>{ user.fullname }</Field.Input>
        </Field.Display>
        <Field.Display>
          <Field.Label><T>user_technicalUser_default_public_issue</T></Field.Label>
          <Field.Input>
            <Form.Simple.InputAdapter name="createdIssueIsPublic">
              {(value, set, clear) => (<InputDefaultPublicIsssue value={ value } set={ set } clear={ clear } /> )}
            </Form.Simple.InputAdapter>
          </Field.Input>
        </Field.Display>
        <Field.Display>
          <Field.Label><T>user_technicalUser_username</T></Field.Label>
          <Field.Input>
            <div className='bs-users-technical-user-info'>
              <Copy value={ user.authentificationProviders[0].username }>{ (value) => <span>{ value }</span>}</Copy>
            </div>
          </Field.Input>
        </Field.Display>
        { password && 
          <Field.Display>
            <Field.Label><T>user_technicalUser_password</T></Field.Label>
            <Field.Input>
              <div className='bs-users-technical-user-info'>
                <Copy value={ password }>
                {(value) => (
                  <HiddenString value={ value} />
                )}
                </Copy>
              </div>
            </Field.Input>
          </Field.Display>
        }
      </Section.Content>
      <Section.Footer className='bs-user-technicalUser-footer'>
        <Button.Text onClick={ close }><T>user_technicalUser_footer_close</T></Button.Text>
        <Button.Text onClick={ submitForm }><T>user_technicalUser_footer_save</T></Button.Text>
        { !password && <Button.Text onDoubleClick={ updatePassword }><T>user_technicalUser_footer_regeneratePassword</T></Button.Text> }
      </Section.Footer>
    </Form.Simple>
  );
}

interface TechnicalUserProps {
  close: () => void
}

const TechnicalUser: FunctionComponent<TechnicalUserProps> = ({ close }) => {
  const api = useService<ApiService>("api");
  const form = React.createRef<FormSimple>();
  const [user, setUser] = React.useState(null);

  const submit = React.useCallback((form: FormSimple, value: any) => {
    if(value.application === "tablet"){
      value = {
        discriminator: "tablet",
        firstname: "Application",
        lastname: value.fullname,
        fullname: value.fullname,
        tenant: value.tenant,
        disabled: false,
        authentificationProviders: value.authentificationProviders
      }
    }
    api.service("users", "post").execute(value).then(user => {
      setUser(value);
    });
  }, [api]);

  const submitForm = React.useCallback(() => {
    form.current.submit(true);
  }, [form]);

  return (
    <div>
      <Section.Header>
        <Section.Header.Text><T>user_technicalUser_creation_title</T></Section.Header.Text>
      </Section.Header>
      <Section.Content>
        <Display.If condition={ user }>
          <Display.Then>
          {() => (
            <DisplayUsernameAndPassword user={ user } />
          )}
          </Display.Then>
          <Display.Else>
          {() => (
            <TechnicalUserForm submit={ submit } ref={ form }/>
          )}
          </Display.Else>
        </Display.If>
      </Section.Content>
      <Section.Footer className='bs-user-technicalUser-footer'>
        <Button.Text onClick={ close }><T>{ `user_technicalUser_footer_${user ? "close" : "cancel"}` }</T></Button.Text>
        { !user && <Button.Text onClick={ submitForm }><T>user_technicalUser_footer_save</T></Button.Text> }
      </Section.Footer>
    </div>
  );
}

export default TechnicalUser;
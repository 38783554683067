import Application from "@universal/lib/application";
import ApiService from "@universal/services/api";
import Tenant from "@universal/types/business/Tenant";
import createUserOnStarter from "../library/PPcreateUserOnStarter";


const getPPTechnicalUser = async (appTest: Application) => {
  const tenants = await appTest.getService<ApiService>("api").service("tenants", "get").execute({
    "settings.commercialOffer": "starter",
    "settings.mainUser": null
  }) as Tenant[];

  if(!tenants.length){
    throw "Aucune structure Starter sans utilisateur principal trouvée";
  }

  const users = await appTest.getService<ApiService>("api").service("users", "get").execute({
    "tenant": tenants[0]._id,
    discriminator: "technical",
    application: "jvs-pp"
  });

  if(!users.length){
    throw "Aucun utilisateur technique trouvé pour la structure";
  } 

  return { user: users[0], tenant : tenants[0] };
};

export default createUserOnStarter("Création d'un utilisateur manager sur une structre starter n'en ayant pas", getPPTechnicalUser);

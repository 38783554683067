import ObjectId from '../technic/ObjectId';
import I18N from '../technic/I18N';
import { HolidayDay } from '@uLib/holidayDay';
import File from './File';
import BsDate from '../technic/Date';
import GeoJson from '../technic/GeoJson';

export const CommercialOffer = {
  none: null,
  starter: "starter",
  standard: "standard",
  expert: "expert",
}

export type CommercialOfferType = null | "starter" | "standard" | "expert";

export interface GenericBase {
  name: string;
}

export interface DayAndMonth extends GenericBase {
  discriminator: 'dayAndMonth',
  params: {
    day: number;
    month: number;
  }
}

export type Generic = DayAndMonth;

export interface HolidayDays {
  standarts: HolidayDay[],
  generics: Generic[]
}

export type WeekDayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface Tenant {
  _id: ObjectId<Tenant>;
  discriminator: string;
  name: I18N;
  clientIdJvs: string;
  settings: {
    allowPublicSpaceIssue: boolean,
    holidayDays: HolidayDays,
    workingDays: WeekDayNumber[],
    commercialOffer: CommercialOfferType,
    citizen:{
      allowed: boolean,
      defaultEmail: string;
      adminIssuesPublicByDefault: boolean;
    },
    defaultMap: {
      zoomLevel: number,
      position: GeoJson
    },
    usersProLimitedToSettings: boolean
  },
  informations: {
    pictures: {
      logo: ObjectId<File>
    },
    postalCode?: string[],
    siren: string,
  };
  cityCode: string,
  country: string,
  connector: null | {
    gp: null | {
      type: string
    }
  },
  createdAt: BsDate
}


export default Tenant;
import SortRule from "@universal/types/technic/Sort";
import _ from 'lodash';

const compareNumber = (a: number, b: number): number => {
  return a - b;
}

const compareString = (a: string, b: string): number => {
  return a.localeCompare(b, 'fr');
}

const compareBoolean = (a: boolean, b: boolean): number => {
  if(a === b) return 0;
  return a ? 1 : -1;
}

const compareDate = (a: Date, b: Date): number => {
  return a.getTime() - b.getTime();
}

const compareObject = (a: any, b: any): number => {
  return 0;
}

const compareArray = (a: any[], b: any[]): number => {
  return 0;
}

const compare = (a: any, b: any): number => {
  if(a === b) return 0;
  if(a === undefined || a === null) return -1;
  if(b === undefined || b === null) return 1;

  if(typeof a === 'number') return compareNumber(a, b);
  if(typeof a === 'string') return compareString(a, b);
  if(typeof a === 'boolean') return compareBoolean(a, b);
  if(a instanceof Date) return compareDate(a, b);
  if(Array.isArray(a)) return compareArray(a, b);
  if(typeof a === 'object') return compareObject(a, b);

  return 0;
}


class Sorter<Type> {

  public static create<Type>(rules: SortRule<Type> | Sorter<Type>): Sorter<Type> {
    if(rules instanceof Sorter) {
      return rules;
    }
    return new Sorter(rules);
  }

  private rules: SortRule<Type>;

  constructor(rules: SortRule<Type>) {
    this.rules = rules;
  }

  join(sortRule: SortRule<Type> | Sorter<Type>): Sorter<Type> {
    if(sortRule instanceof Sorter) {
      sortRule = sortRule.rules;
    }
    return new Sorter<Type>({
      ...this.rules,
      ...sortRule,
      ...this.rules
    });
  }

  compare = (a: Type, b: Type) => {
    let ret = 0;
    
    for(const [path, rule] of Object.entries(this.rules)) {
      const sPath = path.split('.');
      const aValue = _.get(a, sPath);
      const bValue = _.get(b, sPath);

      ret = compare(aValue, bValue) * (rule as number);
      if(ret !== 0) {
        break;
      }
    }

    return ret;
  }

  toJSON() {
    return this.rules;
  }

  clone(){
    return new Sorter<Type>(this.rules);
  }

  get sortRule(): SortRule<Type> {
    return Object.assign({}, this.rules);
  }
}

export default Sorter;
import Criterion, { Filter } from "@uLib/filter";
import { NotificationSubscription } from "@universal/types/business/User";
import ObjectId from "@universal/types/technic/ObjectId";

const factory = (subscriptions: NotificationSubscription[], userId: ObjectId): Filter => {
  const isNotificationAllowed = (name: string) => !!subscriptions.find((subscription) => subscription.name === name && subscription.channels.includes("push"));
  const query = { $or:[ ] };
  if (isNotificationAllowed("onIssueCreatedAsManager")) {
    query.$or.push({ type: "create", "issue.manager._id": userId });
  }
  if (isNotificationAllowed("onCitizenCommentAsManager")) {
    query.$or.push({ type: "comment", "issue.manager._id": userId, creatorCitizen: true});
  }
  if (isNotificationAllowed("onIssueStateUpdatedAsManager")) {
    query.$or.push({ type: "stateUpdate", "issue.manager._id": userId });
  }
  if (isNotificationAllowed("onIssueUpdatedAsManager")) {
    query.$or.push({ type: "update", "issue.manager._id": userId });
  }
  if (isNotificationAllowed("onIssueCreatedAsFollower")) {
    query.$or.push({ type: "create", "issue.$followed": true });
  }
  if (isNotificationAllowed("onIssueStateUpdatedAsFollower")) {
    query.$or.push({ type: "stateUpdate", "issue.$followed": true });
  }
  if (isNotificationAllowed("onCommentAsReceiver")) {
    query.$or.push({ type: "comment", creatorCitizen: false });
  }
  if (isNotificationAllowed("onAssignedToAnIssue")) {
    query.$or.push({ type: "assignment" });
  }

  return Criterion.factory(query);
}

export default factory;
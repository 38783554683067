import Button from '@common/components/button';
import useApplication from '@universal/hooks/useApplication';
import React, { FunctionComponent } from 'react';
import getChacheService from '@services/cache';
import useForceUpdate from '@universal/hooks/useForceUpdate';
import ConfigurationService from '@universal/services/configuration';
import useService from '@universal/behaviour/hooks/useService';
import CacheService from '@universal/services/cacheService';

import './cacheButton.css';

const ButtonActivated = Button.withStyle(Button.Stylized.textGreen.noPadding);
const ButtonDesactivated = Button.withStyle(Button.Stylized.textRed.noPadding);

type ActiveInactiveProps = {
  forceUpdate: () => void;
}

const ActiveInactive: FunctionComponent<ActiveInactiveProps> = ({ forceUpdate }) => {
  const application = useApplication();

  const desactivateCache = React.useCallback(async () => {
    await application.removeServices(["cache"]);
    forceUpdate();
  }, [application]);

  const activateCache = React.useCallback(async () => {
    const cacheService = getChacheService();
    await application.initializeServices([cacheService]);
    forceUpdate();
  }, [application]);

  const activatedButton = React.useMemo(() => (
    <ButtonActivated onClick={ desactivateCache }>
      <span className='fa fa-check'/>
    </ButtonActivated>
  ), [desactivateCache]);

  const desactivatedButton = React.useMemo(() => (
    <ButtonDesactivated onClick={ activateCache }>
      <span className='fa fa-times'/>
    </ButtonDesactivated>
  ), [activateCache]);

  return application.servicesContainer.has("cache")
    ? activatedButton
    : desactivatedButton;
}

const ButtonWithoutValidation = Button.withStyle(Button.Stylized.textGray.noPadding);
const ButtonWithValidation = Button.withStyle(Button.Stylized.textRed.noPadding);

const ValidationButton: FunctionComponent = () => {
  const cacheService = useService<CacheService>("cache");
  const forceUpdate = useForceUpdate();


  const toggleValidation = React.useCallback(() => {
    cacheService.validateMode = !cacheService.validateMode;
    forceUpdate();
  }, [cacheService, forceUpdate]);

  const withValidationButton = React.useMemo(() => (
    <ButtonWithValidation onClick={ toggleValidation }>
      <span className='fa fa-heartbeat'/>
    </ButtonWithValidation>
  ), [toggleValidation]);

  const withoutValidationButton = React.useMemo(() => (
    <ButtonWithoutValidation onClick={ toggleValidation }>
      <span className='fa fa-heart-o'/>
    </ButtonWithoutValidation>
  ), [toggleValidation]);

  return cacheService.validateMode
    ? withValidationButton
    : withoutValidationButton;
}

const CacheButton: FunctionComponent = () => {
  const configuration = useService<ConfigurationService>("configuration");
  const application = useApplication();
  const forceUpdate = useForceUpdate();

  if(configuration.get("env") !== "development") {
    return null;
  }

  return (
    <div className="bs-cacheButton">
      <span>Cache :</span>
      <span><ActiveInactive forceUpdate={ forceUpdate } /></span>
      {
        application.servicesContainer.has("cache")
          ? <span><ValidationButton /></span>
          : null
      }
    </div>
  );
};

export default CacheButton;
import Application from "@universal/lib/application";
import generateRandomelyString from "./generateRandomelyString";
import ApiService from "@universal/services/api";

const createMainUser = async (appPP: Application, appAdmin: Application) => {
  let email;
  let users = [];
  do {
    email = generateRandomelyString(32) + "@test.com"
    users = await appAdmin.getService<ApiService>("api").service("users", "get").execute({
      "authentificationProviders.username": email
    });
  }while(users.length);

  await appPP.getService<ApiService>("api").service("users", "panneaupocketCreate").execute({
    email: email,
    firstname: generateRandomelyString(10),
    lastname: generateRandomelyString(15),
    phone: "0123456789"
  });
}

export default createMainUser;
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import SessionService from "@universal/services/session";

type StepCreation = { application: Application, username: string, password: string };


export default (namespace: string, createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest(namespace, "Connexion de l'utilisateur technique")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Connexion de l'utilisateur", async ({ application, username, password }: StepCreation) => {
      await application.getService<SessionService>("session").login(username, password);
      return {};
    })
    .build();
};
import Flatten from "./Flatten";
import ObjectId from "./ObjectId"

interface Entity<T>{
  id: ObjectId;
  toPlainText: () => T;
  dispose: () => void;
};

export type Loader  = object | boolean;

type GetLoader<Loaded, Property> = Property extends keyof Loaded
  ? Loaded[Property] extends Loader
    ? Loaded[Property]
    : void
  : void;


type BusinessEntityExtractor<Entity, Loaded extends Loader | void> = {
  [Property in keyof Entity]: Entity[Property] extends ObjectId<infer SubEntity>
    ? (GetLoader<Loaded, Property> extends Loader
      ? BusinessEntity<SubEntity, GetLoader<Loaded, Property>>
      : Entity[Property])
    : Entity[Property]
}

export type BusinessEntity<T, Loaded extends Loader | void = void> = BusinessEntityExtractor<T, Loaded> & Entity<T>;

export type Entityable<Type> = Type | BusinessEntity<Type>;

export const isEntity = <T>(entity: Entityable<T>): entity is BusinessEntity<T> => {
  return !!(entity as BusinessEntity<T>)?.toPlainText;
};

export default Entityable;
import Application from "@universal/lib/application";
import { TestBuilder } from "../library/test";
import SessionService from "@universal/services/session";
import ApiService from "@universal/services/api";
import generateRandomelyString from "../library/generateRandomelyString";
import { StartHandler } from "../library/createApplication";
import MemoryStorageService from "@universal/services/memoryStorage";
import extractToken from "../library/extractToken";

type StepCreation = {
  appPP: Application,
  appAdmin: Application
};

const countIssues = (app: Application, userId: string) => app.getService<ApiService>("api").service("issues", "count").execute({
  createdBy: userId
});

export const createIssueWithEmailValidation = (createApplication: () => Promise<StepCreation>, createValidationApp: (beforeStart: StartHandler) => Promise<Application>, title: string) => {
  return TestBuilder.createTest("PanneauPocket", title)
    .addStep("Préparation de l'application", createApplication)
    .addStep("Création du signalement", async ({ appPP, appAdmin }: StepCreation) => {
      const user = appPP.getService<SessionService>("session").user;

      const nbrIssuesBeforeAll = await countIssues(appPP, user._id);

      let email = null;
      let idRequestor = null;
      let issues = [];
      do {
        email = generateRandomelyString(32) + "@test.com";
        idRequestor = generateRandomelyString(24);

        issues = await appPP.getService<ApiService>("api").service("issues", "get").execute({
          "requestor.email": email
        });

      } while(issues.length) ;

      const categories = await appPP.getService<ApiService>("api").service("categories", "get").execute({});
      if(!categories.length){
        throw new Error("Aucune catégorie n'est disponible");
      }

      const result = await appPP.getService<ApiService>("api").service("issues", "post").execute({
        description: "Test api création d'un signalement par PP",
        category: categories[0]._id,
        requestor: {
          id: idRequestor,
          type: "citizen",
          firstname: "Faux",
          lastname: "Utilisateur",
          email
        },
        files: [],
        discriminator: "publicSpace",
        location: {
          address: {
            streetNumber: "20",
            street: "Guido Gezellestraat",
            locality: "Aalter",
            country: "BE",
            zip: "9880"
          },
          position: {
            type: "Point",
            coordinates:[ 3.4454584121704106, 51.040105422904276]
          }
        }
      });

      if(!result.mailMustBeValidated){
        throw new Error("L'email du demandeur devrait être validé mais il ne l'est pas");
      }
      const nbrIssuesBeforeValidation = await countIssues(appPP, user._id);

      if(nbrIssuesBeforeValidation !== nbrIssuesBeforeAll){
        throw new Error("Le nombre de signalements a augmenté alors qu'il ne devrait pas");
      }

      return {
        email,
        idRequestor,
        appPP,
        appAdmin,
        nbrIssuesBefore: nbrIssuesBeforeAll
      };
    }).addStep("Validation de l'email", async (params: StepCreation & { nbrIssuesBefore: number }) => {
      const { appPP, appAdmin, nbrIssuesBefore } = params;

      const token = await extractToken(appAdmin);
      const appValidation = await createValidationApp(async (app) => {
        await app.getService<MemoryStorageService>("temporaryStorage").set("bsAdminToken", token);
      });

      const user = appPP.getService<SessionService>("session").user;
      const nbrIssuesAfterValidation = await countIssues(appPP, user._id);

      if(nbrIssuesAfterValidation !== nbrIssuesBefore + 1){
        throw new Error("Le nombre de signalements n'a pas augmenté après la validation de l'email");
      }

      return params;
    });
};

export default (createApplication: () => Promise<StepCreation>, createValidationApp: (beforeStart: StartHandler) => Promise<Application>) => {
  return createIssueWithEmailValidation(createApplication, createValidationApp, "Création d'un signalement avec une adresse email non validé").build();
};
import md5 from 'md5';
import DelayCache from './delayCache';

type Handler<Type> = (...args: any[]) => Promise<Type>;

class HandlerCache<Type> {
  private cache: DelayCache<Type>;
  
  private _handler: Handler<Type>;

  constructor(handler: Handler<Type>, delay: number = 1800000) {
    this._handler = handler;
    this.cache = new DelayCache<Type>(delay);
  }

  get handler(): Handler<Type>{
    return this._handler;
  }

  async get(...args: any[]): Promise<Type> {
    const key = md5(JSON.stringify(args));
    if (this.cache.has(key)) {
      return Promise.resolve(this.cache.get(key));
    }
    const result = await this._handler(...args);
    this.cache.set(key, result);
    return result;
  }

  update(parameters: any, result: Type) {
    const key = md5(JSON.stringify(parameters));
    this.cache.set(key, result);
  }

  clear() {
    this.cache.dispose();
  }
}

export default HandlerCache;
import StrategyCollection from "./strategyCollection";
import DelayCache from "../cache/delayCache";
import SortRule from "@universal/types/technic/Sort";
import Query from "@universal/types/technic/Query";
import ICollection from "./iCollection";
import Sorter from "../sorter";
import md5 from "md5";

class CachedCollection<Type> extends StrategyCollection<Type> {
  private cache: DelayCache<ICollection<Type>>;

  constructor(collection: ICollection<Type>, delay: number) {
    super(collection);
    this.cache = new DelayCache<ICollection<Type>>(delay);
  }

  dispose(){
    this.cache.dispose();
  }

  find(query: Query<Type> = {}, sort: SortRule<Type> | Sorter<Type>): ICollection<Type> {
    const key = md5(JSON.stringify({ query, sort }));
    if (!this.cache.has(key)) {
      this.cache.set(key, super.find(query, sort));
    }
    return this.cache.get(key);
  }

  add(item: Type) {
    super.add(item);
    for(const cache of this.cache) {
      cache.dataWithoutDelay.add(item);
    }
  }

  addMany(items: Type[]) {
    super.addMany(items);
    for(const cache of this.cache) {
      cache.dataWithoutDelay.addMany(items);
    }
  }

  drop(item: Type) {
    super.drop(item);
    for(const cache of this.cache) {
      cache.dataWithoutDelay.drop(item);
    }
  }

  dropMany(items: Type[]) {
    super.dropMany(items);
    for(const cache of this.cache) {
      cache.dataWithoutDelay.dropMany(items);
    }
  }

  clear() {
    super.clear();
    this.cache.dispose();
  }
}

export default CachedCollection;
import { getRandomInt } from "@root/old/lib/tool";

const generateRandomelyString = (size: number) => {
  let str = "";
  for(let i = 0; i < size; i++){
    str += String.fromCharCode(getRandomInt(97, 122));
  }
  return str;
}

export default generateRandomelyString;
import React        from 'react';
import T            from '@cBehaviour/i18n';
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';

import './item.css';

const getTypeName = (type) => {
    switch(type) {
        case "publicSpace":
            return <T>public_space</T>;
        case "building":
            return <T>building</T>;
        case "equipment":
            return <T>equipment</T>;
        default:
            return <T>unknown</T>

    }
}
const CategoryRedirect = (props) => (
    <>
        <T>{ props.category.label }</T> - <T>{props.category.tenant.name}</T>
    </>
);

const getRedirectionInfos = (redirectRule) => { 
    if (!redirectRule) return <T>default_email</T>;

    switch(redirectRule.discriminator) {
        case "manager":
            return redirectRule.manager.fullname;
        case "monoTenant":
            return (<CategoryRedirect category={ redirectRule.category } />);
        case "multiTenant":
            return redirectRule.categories.map(category => (
                <CategoryRedirect category={ category } />
            ));
        default: 
            return <T>undefined</T>;
    }
}

const CategoryItem = ({ data: category }) => {
  return (
    <Item className="bs-category-item">
      <Item.Content>
        <Layout.Standart className="bs-category-content" stretch>
          <Layout.Standart.Header>
            <Title><T>{category.label}</T></Title>
          </Layout.Standart.Header>
          <Layout.Standart.Content className="bs-category-description">
          <div>
            <span><T>type</T> : </span>
            <span>{getTypeName(category.type)}</span>
          </div>
          <Display.If condition={category.redirectRule?.defaultTransfer}>
            {() => (
              <div className="bs-default-transfer">
                <span><T>category_item_default_transfer</T> : </span>
                <span>{category.redirectRule.defaultTransfer.name}</span>
              </div>  
            )}                                       
          </Display.If>
          </Layout.Standart.Content>
          <Layout.Standart.Footer className="bs-action">
            <div className="bs-redirection-infos">
              <span><T>redirect_to</T> : </span>
              <span>{getRedirectionInfos(category.redirectRule)}</span>                        
            </div>
            <div>
              <span><T>visibility</T> : </span>
              <span>
                <Display.If condition={category.visibleByCitizen}>
                  <Display.Then>
                    <span><T>citizens</T></span>
                    <span>, </span>
                  </Display.Then>
                </Display.If>
                <span><T>internals</T></span>
              </span>
            </div>
          </Layout.Standart.Footer>
        </Layout.Standart>
      </Item.Content>
    </Item>    
  );
}

CategoryItem.Load = { 
  tenant: true, 
  "redirectRule.manager": true,
  "redirectRule.category": {
    tenant: true
  }, 
  "redirectRule.categories": {
    tenant: true
  }, 
  "redirectRule.defaultTransfer": true 
};

export default CategoryItem;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-filters-manager-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.bs-filters-add-btn:hover {
  cursor: pointer;
}

.bs-filters-manager-item-containers {
  display: flex;
  justify-content: space-between;
}

.bs-filters-manager-defaultFilter {
  margin-left: 10px;
}

.bs-filters-manager-content .bs-button-radio {
  color: white;
}

.bs-filters-manager-items-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./../common/features/filter/manager.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".bs-filters-manager-title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 16px;\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.bs-filters-add-btn:hover {\n  cursor: pointer;\n}\n\n.bs-filters-manager-item-containers {\n  display: flex;\n  justify-content: space-between;\n}\n\n.bs-filters-manager-defaultFilter {\n  margin-left: 10px;\n}\n\n.bs-filters-manager-content .bs-button-radio {\n  color: white;\n}\n\n.bs-filters-manager-items-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

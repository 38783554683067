import React        from "react";
import Slot         from "@uComponents/slot";
import Display      from "@uComponents/displayIf";
import papaparse    from "papaparse";
import fileDownload from "js-file-download";
import NoData       from "./noData";
import Window       from '@cComponents/window';

import "./frame.css";
const toCsv = (object) => {
  fileDownload("\ufeff" + papaparse.unparse(object.datas, { delimiter: ";" }), object.title + ".csv", "text/csv");
}
const print = (nodes, title) => {
  Window.open("/print/statistics", title || "Impressions", "menubar=no,location=no,resizable=no,scrollbars=no,status=no", (elements, window) => {
    elements.title.appendChild(nodes.title.cloneNode(true));
    elements.graphic.appendChild(nodes.graphic.cloneNode(true));
    elements.filters.appendChild(nodes.filters.cloneNode(true));
    window.print();
    window.close();
  });
};
const Frame = (props) => {
  const refs = {
    title: React.createRef(),
    graphic: React.createRef(),
    filters: React.createRef()
  };
  let datas = props.datas;
  if(props.transform){
    datas = props.transform(props.datas);
  }
  const cProps  = Frame.Content.props({ props });
  let component = Frame.Content.get({ props });
  const componentProps = { datas: datas };
  const style = {};
  if(cProps.height){
    style.height = cProps.height;
  }
  if(props.extra){
    props.extra(componentProps, props.datas);
  }
  if(component){
    if(component instanceof Function){
      component = component(componentProps);
    }else{
      component = React.cloneElement(component, componentProps);
    }
  }else {
    component = null;
  }
  return (
    <>
      <div className="bs-statistics-frame">
        <div className="bs-statistics-frame-title" ref={ refs.title }>
          { Frame.Title.get({ props }) }
          <Display.If condition={ props.toCsv && datas.length }>
            <div className="bs-statistics-frame-title-toCsv bs-no-print" onClick={ () => toCsv(props.toCsv(props.datas, datas))}>
              <span className="fa fa-file-excel-o" />
            </div>
          </Display.If>
          <div className="bs-statistics-frame-title-print bs-no-print" onClick={() => print(Object.keys(refs).reduce((acc, property) => {
            acc[property] = refs[property].current;
            return acc;
          }, {}), props.pageTitle ) }>
            <span className="fa fa-print" />
          </div>
        </div>
        <div className="bs-statistics-frame-content" style={ style } ref={ refs.graphic }>
          <Display.If condition={ props.force || props.datas.length }>
            <Display.Then>
              { component }
            </Display.Then>
            <Display.Else>
              <NoData />
            </Display.Else>
          </Display.If>
        </div>
      </div>
      <div className="bs-statistics-frame-filter" ref={ refs.filters }>
        Filtre actif(
        { props.getFilter().reduce((acc, f) => {
          acc.push(<span>&nbsp;</span>);
          acc.push(f);
          return acc;
        }, []) }
        )
      </div>
    </>
  );
};
Frame.Title = Slot();
Frame.Content = Slot();

export default Frame;
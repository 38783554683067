import Application from "@universal/lib/application";
import SessionService from "@universal/services/session";
import ApiService from "@universal/services/api";
import { StartHandler } from "../library/createApplication";
import { createIssueWithEmailValidation } from "./PPcreateIssueWithEmailValidation";

type StepCreation = {
  appPP: Application,
  appAdmin: Application,
  email: string,
  idRequestor: string
};

const countIssues = (app: Application, userId: string) => app.getService<ApiService>("api").service("issues", "count").execute({
  createdBy: userId
});

export default (createApplication: () => Promise<StepCreation>, createValidationApp: (beforeStart: StartHandler) => Promise<Application>) => {
  return createIssueWithEmailValidation(createApplication, createValidationApp, "Création d'un signalement sur un email déjà validé")
    .addStep("Création du signalement avec les informations validés", async ({ appPP, appAdmin, email, idRequestor }: StepCreation) => {
      const user = appPP.getService<SessionService>("session").user;

      const nbrIssuesBeforeAll = await countIssues(appPP, user._id);

      const categories = await appPP.getService<ApiService>("api").service("categories", "get").execute({});
      if(!categories.length){
        throw new Error("Aucune catégorie n'est disponible");
      }
      
      const result = await appPP.getService<ApiService>("api").service("issues", "post").execute({
        description: "Test api création d'un signalement par PP",
        category: categories[0]._id,
        requestor: {
          id: idRequestor,
          type: "citizen",
          firstname: "Faux",
          lastname: "Utilisateur",
          email
        },
        files: [],
        discriminator: "publicSpace",
        location: {
          address: {
            streetNumber: "20",
            street: "Guido Gezellestraat",
            locality: "Aalter",
            country: "BE",
            zip: "9880"
          },
          position: {
            type: "Point",
            coordinates:[ 3.4454584121704106, 51.040105422904276]
          }
        }
      });

      if(result.mailMustBeValidated !== undefined){
        throw new Error("L'email du demandeur devrait être validé mais il ne l'est pas");
      }
      const nbrIssuesBeforeValidation = await countIssues(appPP, user._id);

      if(nbrIssuesBeforeValidation !== nbrIssuesBeforeAll + 1){
        throw new Error("Le nombre de signalements n'a pas augmenté alors qu'il aurait du");
      }
    }).build();
};
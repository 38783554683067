
const extendEntitiesManager = {
  get: (entities, name) => {
    for(let entity of entities) {
      if(entity[name] !== undefined) {
        if(entity[name] instanceof Function) {
          return entity[name].bind(entity);
        }
        return entity[name];
      }
    }
  }
}

const extendEntities  = (...objects) => {
  return new Proxy(objects, extendEntitiesManager);
}

export default extendEntities;
import Application from "@universal/lib/application";
import MockRequesterService from "./mockRequesterService";

const extractToken = async (app: Application) => {
  const calls = await app.getService<MockRequesterService>("mock").get();
  if(calls.emails.calls.length !== 1){
    throw "Mail non identifiable";
  }
  const { html } = calls.emails.calls[0].params;
  const div = document.createElement("div");
  div.innerHTML = html;
  const a = div.querySelector("a");
  if(!a){
    throw "Lien de validation non trouvé";
  }
  return decodeURIComponent(a.href.split("=")[1]);
}

export default extractToken;
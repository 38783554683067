import ApiService from "@universal/services/api";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import Issue from "@universal/types/business/Issue";
import { sleep } from "@universal/lib/promise";
import CurrentTenantService from "@universal/services/currentTenant";
import ObjectId from "@universal/types/technic/ObjectId";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import MockRequesterService from "../library/mockRequesterService";


type StepCreation = { appAc: Application, appAdmin: Application };


export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("AttractiveCity", "Test que la modification du statut et l'ajout commentaire d'un signalement fait appelle au service AC")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération d'un signalement par l'utilisateur AC", async ({ appAc, appAdmin }: StepCreation) => {
      const repository = appAc.getService<RepositoryService>("repository").get("Issue").repository;
      const issues = await repository.find({ }, {}) as BusinessEntity<Issue>[];
      if(!issues.length){
        throw "Aucun signalement trouvé";
      }
      const issue = issues[0];
      const currentTenant = appAdmin.getService<CurrentTenantService>("currentTenant");
      
      await currentTenant.setCurrentId(issue.toPlainText().tenant as ObjectId);
      return { issue, appAdmin };
    })
    .addStep("Changement d'état du signalement avec envoie d'un commentaire", async ({ issue, appAdmin }: { appAdmin: Application, issue: Issue }) => {
      let state = "resolved";
      if(issue.state === "resolved"){
        state = "open";
      }
      await appAdmin.getService<ApiService>("api").service("issues", "updateState").execute(issue._id, state, null, "Test commentaire AC");
      await sleep(2000);
      return { appAdmin };
    })
    .addStep("Test si appelle au service AC", async ({ appAdmin }: { appAdmin: Application }) => {
      const logs = await appAdmin.getService<MockRequesterService>("mock").get();
      if(logs.attractiveCity){
        if(logs.attractiveCity.calls.length === 2){
          if(logs.attractiveCity.calls[0].type === "event" && logs.attractiveCity.calls[0].name === "issues.stateUpdated"){
            if(logs.attractiveCity.calls[1].type === "event" && logs.attractiveCity.calls[1].name === "comments.created"){
              return;
            }
          }
        }
      }
      throw new Error("Appelle au service AC non effectué");
    })
    .build();
};
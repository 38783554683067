import DevelopProperty from "@universal/types/technic/DevelopProperty";
import Query from "@universal/types/technic/Query";
import SortRule from "@universal/types/technic/Sort";
import _ from 'lodash';
import md5 from 'md5';

class Key<Type> {
  static create<Type>(properties: (keyof DevelopProperty<Type> & string)[] | keyof DevelopProperty<Type> & string | Key<Type>) {
    if (properties instanceof Key) {
      return properties;
    }
    if (!Array.isArray(properties)) {
      properties = [properties];
    }
    return new Key(properties)
  }

  private _properties: (keyof DevelopProperty<Type> & string)[];

  constructor(properties: (keyof DevelopProperty<Type> & string)[]) {
    this._properties = properties
  }
  
  get properties(): (keyof DevelopProperty<Type> & string)[]{
    return this._properties.slice();
  }

  extract(object: Partial<Type>): DevelopProperty<Type> {
    const result: DevelopProperty<Type> = {} as DevelopProperty<Type>;
    this._properties.forEach(prop => {
      const path = (prop as string).split('.');
      _.set(result, prop, _.get(object, path));
    })
    return result
  }

  hash(object: Partial<Type>): string {
    return md5(JSON.stringify(this.extract(object)))
  }

  equals(a: Type, b: Type): boolean {
    return this.hash(a) === this.hash(b);
  }

  valuesToQuery(value: any[]): Partial<Type>{
    return this._properties.reduce<Partial<Type>>((query, property, index) => {
      query[property as unknown as (keyof Type) & string] = value[index];
      return query;
    }, {});
  }

  queryToValues(query: Partial<Type>): any[]{
    return Object.keys(query).map(key => query[key as keyof Type]);
  }

  toQuery(datas: Type[]): Query<Type>{
    if(!datas.length){
      throw new Error('datas must have minimum one occurence');
    }
    if(this.properties.length === 1){
      const path = (this.properties[0] as string).split('.');
      return { [this.properties[0]]: { $in: datas.map(data => _.get(data, path)) }};
    }else{
      const pathes = this.properties.map(property => ({ property, path: (property as string).split('.') }));
      return {
        $or: datas.map(data => {
          return { 
            $elemMatch: pathes.reduce((acc, path) => {
              acc[path.property] = _.get(data, path.path);
              return acc;
            }, {})
          };
        })
      };
    }
  }

  get sortRule(): SortRule<Type>{
    return this._properties.reduce<SortRule<Type>>((acc: SortRule<Type>, prop: keyof SortRule<Type>) => {
      acc[prop] = 1;
      return acc;
    }, {});
  }
}

export default Key;
import React, { FunctionComponent } from 'react';

import { IStep, State as StateValue } from '../library/test';
import Display from '@universal/components/displayIf';
import State from './state';
import useForceUpdate from '@universal/hooks/useForceUpdate';
import { Listener } from '@universal/lib/event';

import './step.css';

type StepProps = {
  step: IStep;
};

type DisplayErrorType = (error: any) => string;

const displayError: DisplayErrorType = (error) => {
  if(!error || error + "" === error){
    return error;
  }
  if(error.message){
    return error.message;
  }
  return "Unparsable error";
}

const Step: FunctionComponent<StepProps> = ({ step }) => {
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    const listener = new Listener(forceUpdate);
    step.onStateChange.addListener(listener);
    return () => {
      step.onStateChange.removeListener(listener);
    };
  }, [step]);

  return (
    <div className='bs-test-step'>
      <div className='bs-test-step-content'>
        <div>{ step.description }</div>        
        <div><State state={ step } /></div>
      </div>
      <Display.If condition={ step.state === StateValue.ERROR }>
        <div className='bs-test-step-error'>{ displayError(step.error) }</div>
      </Display.If>
    </div>
  );
};

export default Step;
import I18nService from '@universal/services/i18n';
import { Helper } from '../lib/application';
import StringBuilder from '../lib/stringBuilder';
import Tenant from '../types/business/Tenant';
import ObjectId from '../types/technic/ObjectId';

class TenantHelper extends Helper {
  constructor() {
    super('tenant', ['i18n', 'currentTenant', "api"]);
  }

  get currentTenantLabel(){
    const currentTenant = this.application.getService("currentTenant");
    if(!currentTenant.isSelected()){
      return "";
    }
    return this.getTenantLabel(currentTenant.current);
  }

  getTenantLabel(tenant: Tenant) {
    const i18n = this.application.getService<I18nService>("i18n");
    const postalCodes: string[] | undefined = tenant.informations.postalCode;
  
    return StringBuilder.create(" - ")
      .add(i18n.translate(tenant.name))
      .addIfExist(postalCodes?.length ? postalCodes.join(',') : null)
      .addIfExist(tenant.informations.siren)
      .add(tenant.country.toUpperCase())
      .build();
  }
  
  inviteNotClient(tenantId: ObjectId, defaultEmail: String, type: String) {
    const i18n = this.application.getService("i18n");
    const api = this.application.getService("api");
    const message = this.application.getService("message");

    return api.service("tenants", "inviteNotClient").execute(tenantId, defaultEmail, type)
    .then(() => {
      message.send("info", i18n.translate("tenant_invite_not_client_success"));
      return true;
    });
  }

  inviteSelf(tenantId: ObjectId) {
    const api = this.application.getService("api");
    const message = this.application.getService("message");
    const i18n = this.application.getService("i18n");

    return api.service("tenants", "inviteSelf").execute(tenantId)
    .then(() => {
      message.send("info", i18n.translate("tenant_invite_not_client_success"));
      return true;
    })
    .catch((e) => {
      message.send("warning", i18n.translate(e.message));
      return true;
    });
  }
}

export default TenantHelper;
import Application from "@universal/lib/application";
import SessionService from "@universal/services/session";
import { TestBuilder } from "../library/test";
import ApiService from "@universal/services/api";

type StepCreation = {
  appPP: Application,
  appAdmin: Application
};

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("PanneauPocket", "Récupération des signalements")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération des signalements", async ({ appPP, appAdmin }: StepCreation) => {
      const user = appPP.getService<SessionService>("session").user;

      const nbrIssuesPP = await appPP.getService<ApiService>("api").service("issues", "count").execute({});
      const nbrIssuessAdmin = await appAdmin.getService<ApiService>("api").service("issues", "count").execute({
        createdBy: user._id
      });

      if(nbrIssuesPP !== nbrIssuessAdmin){
        throw new Error("L'utilisateur PP n'a pas accès à toutes les signalements (PP: " + nbrIssuesPP + " / Admin: " + nbrIssuessAdmin + ")");
      }
    }).build();
};
import React, { FunctionComponent } from 'react';
import T from "@uBehaviour/i18n";
import Menu from "@cComponents/menu";
import { Redirect, Route, Switch } from '@cFeatures/router';
import Acl from '@uBehaviour/acl';
import Configuration from "./configuration";
import Citizen from "./citizen";
import Campaign from "./campaign";
import Test from "./test";

interface HeaderToolsProps {

}

const HeadersTools: FunctionComponent<HeaderToolsProps> = ({ }) => (
  <Menu.Section>
    <Acl.If resource="application" action="configure">
      <Menu.Button path={`/configuration`} icon="gears">
        <T>general_configuration</T>
      </Menu.Button>
      <Menu.Button path={`/citizen`} icon="users">
        <T>citizens</T>
      </Menu.Button>
      <Menu.Button path={`/campaign`} icon="blind">
        <T>screen_tool_campaign</T>
      </Menu.Button>
      <Menu.Button path={`/test`} icon="heart">
        <T>screen_tool_test</T>
      </Menu.Button>
    </Acl.If>
  </Menu.Section>
);

interface ToolsProps {

}

const Root = () => {
  return (
    <Redirect to='/configuration' />
  );
}

const Tools: FunctionComponent<ToolsProps> = ({ }) => {
  return (
    <>
      <HeadersTools />
      <Switch>
        <Route path="/configuration" component={ Configuration.Form } />
        <Route path="/citizen" component={ Citizen.List } />
        <Route path="/campaign" component={ Campaign.List } />
        <Route path="/test" component={ Test } />
        <Route path="/" component={ Root } />
      </Switch>
    </>
  )
}

export default Tools;
import React, { FunctionComponent, useMemo } from 'react';
import { IState, State as StateValue } from '../library/test';
import useForceUpdate from '@universal/hooks/useForceUpdate';
import { Listener } from '@universal/lib/event';

import './state.css';

type StateProps = {
  state: IState;
}

const State: FunctionComponent<StateProps> = ({ state }) => {
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    const listener = new Listener(forceUpdate);
    state.onStateChange.addListener(listener);
    return () => {
      state.onStateChange.removeListener(listener);
    };
  }, [state]);

  const className = useMemo(() => {
    const className = 'bs-test-state fa ';
    switch (state.state) {
      case StateValue.PENDING:
        return className + 'fa-clock-o bs-test-state-pending';
      case StateValue.RUNNING:
        return className + 'fa-spinner fa-spin bs-test-state-running';
      case StateValue.SUCCESS:
        return className + 'fa-check bs-test-state-success';
      case StateValue.ERROR:
        return className + 'fa-times bs-test-state-error';
      default:
        return '';
    }
  }, [state.state]);

  return (
    <span className={ className } />
  );
};

export default State;
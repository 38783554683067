import React            from "react";
import T                from "@cBehaviour/i18n";
import SearchDisplay    from "./searchDisplay";
import { escapeRegExp } from "@uLib/tool";
import Application      from "@uBehaviour/application";
import Filter           from "@cComponents/filter";

type SearchProps = {
  issueIdPath: string;
  assignmentsPath: string;
  fluid?: boolean;
};

interface SearchState {
  value: any;
}

class Search extends React.Component<SearchProps, SearchState> {

  private _searched: string;

  constructor(props){
    super(props);
    this._searched = "";
  }

  state = {
    currentValue: null, 
  };

  _search = (value) => {
    if(!value) return Promise.resolve([]);
    return this.props.api.service("sessions", "search").execute(value, this.props.currentTenant.currentId).then(results => 
      results.reduce((acc, result) => {
        if(result.type === "equipment") {
          acc.push({
            type: "equipmentConcerned",
            label: result.label,
            value: {
              ...result,
              type: "equipmentConcerned",
            }
          },
          {
            type: "equipmentUsed",
            label: result.label,
            value: {
              ...result,
              type: "equipmentUsed",
            }
          })
        }
        else acc.push({
          type: result.type,
          label: result.label,
          value: result
        });

        return acc;
      }, []));
  }
  
  _stringifySearchQuery = (value) => {
    if (!value) return ""; 
    switch(value.type){
      case "issue": return (<><T bind={{ issue: value.label }}>issue_filter_search_issue</T></>);
      case "building": return (<><T bind={{ building: value.label }}>issue_filter_search_building</T></>);
      case "equipmentConcerned": return (<><T bind={{ equipment: value.label }}>issue_filter_search_equipment_concerned</T></>);
      case "equipmentUsed": return (<><T bind={{ equipment: value.label }}>issue_filter_search_equipment_used</T></>);
      case "userpro": return (<><T bind={{ user: value.label }}>issue_filter_search_userpro</T></>);
      case "citizen": return (<><T bind={{ citizen: value.label }}>issue_filter_search_citizen</T></>);
      case "contains": return (<><T bind={{ searched: value.label }}>issue_filter_search_freeSearch</T></>);
    }
  }

  _buildSearchQuery = (value) => {
    switch(value.type){
      case "issue": return { [this.props.issueIdPath]: value.value };
      case "building": return { "location.building": value.value };
      case "equipmentConcerned": return { equipment: value.value };
      case "equipmentUsed": return { [`${this.props.assignmentsPath}.necessariesEquipments`]: value.value };
      case "userpro": 
        return { $or: [
          { createdBy: value.value },
          { manager: value.value },
          { updatedBy: value.value },
          { [this.props.assignmentsPath]: {"agents": value.value }}
        ]};
      case "citizen": return { createdBy: value.value };
      case "contains": { 
        const query = { $or: [
          { description: { $regex: value.value, $options: "i" }},
          {"location.address.locality": { $regex: value.value, $options: "i" }},
          {"location.address.street": { $regex: value.value, $options: "i" }},
          {"location.address.streetNumber": { $regex: value.value, $options: "i" }}
        ]};

        if (!Number.isNaN(parseInt(value.value))) {
          query.$or.push({ bsIdNumber: value.value });
        }
        return query;
      }
    }
  }

  _onSearchChange(value, set){
    const excludes = [];
    switch(value.type){
      case "building": excludes.push("buildings", "subs_buildings"); break;
      case "equipmentConcerned":
      case "equipmentUsed"     : excludes.push("equipments"); break;
      case "userpro":
      case "citizen": excludes.push("creators"); break;
    }
    set(value, excludes);
  }

  _onKeyUp(ev, set, clear) {
    if (ev.code === "Enter" || ev.code === "NumpadEnter") {
      if(ev.target.value){
        set({
          type:"contains",
          value: escapeRegExp(ev.target.value),
          label: ev.target.value
        });
      }else{
        clear();
      }
    } else {
      this._searched = ev.target.value;
    }
  }


  shouldComponentUpdate(nextState) {
    if (nextState.currentvalue == undefined && this.state.currentValue != null) {
      this._searched = ""; 
    }
    return true;
  }

  render() {

    if (!this.props.assignmentsPath || !this.props.issueIdPath) {
      throw new Error('Les props "assignmentPath" et "issueIdPath" doivent être définies dans le composant Search');
    }
    return (
      
      <Filter.Generic name="text" buildQuery={this._buildSearchQuery} stringify={this._stringifySearchQuery}>
      {(value, set, clear) => {

        if (value !== this.state.currentValue) {
          this.setState({ currentValue: value });
        }
         
        return (       
          <SearchDisplay          
            value={value}
            searched={this._searched}
            onEdit={clear}
            onDelete={() => { this._searched = ""; clear(); }}
            onChanged={ value => this._onSearchChange(value, set) }
            onKeyUped={ ev => this._onKeyUp(ev, set, clear) }
            search={ this._search }
            fluid={ this.props.fluid }
            stringifySearchQuery={this._stringifySearchQuery(value)}
          />
        )
          
      }}
      </Filter.Generic>
    )
  }
}

export default Application.Service.forward(["api", "currentTenant"], Search);

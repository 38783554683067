import Application from "@universal/lib/application";
import SessionService from "@universal/services/session";
import { TestBuilder } from "../library/test";
import ApiService from "@universal/services/api";

type StepCreation = {
  appPP: Application,
  appAdmin: Application
};

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("PanneauPocket", "Récupération des catégories")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération des catégories", async ({ appPP, appAdmin }: StepCreation) => {
      const user = appPP.getService<SessionService>("session").user;

      const categoriesPP = await appPP.getService<ApiService>("api").service("categories", "count").execute({});
      const categoriesAdmin = await appAdmin.getService<ApiService>("api").service("categories", "count").execute({
        type: "publicSpace",
        tenant: user.tenant
      });

      if(categoriesPP !== categoriesAdmin){
        throw new Error("L'utilisateur PP n'a pas accès à toutes les catégories (PP: " + categoriesPP + " / Admin: " + categoriesAdmin + ")");
      }
    }).build();
};
import React, { useContext, ContextType, FunctionComponent } from 'react';
import _              from 'lodash';
import FilterForm     from "./form";
import Collapsable    from '@cComponents/collapsable';
import CFilter        from '@cComponents/filter';
import Display        from '@uComponents/displayIf';
import Modal          from "@cComponents/modal";
import Input          from '@cComponents/input';
import useOpenCloseToggle   from '@uBehaviour/hooks/useOpenCloseToggle';
import useService           from "@uBehaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import ApiService           from "@universal/services/api";
import MessageService       from "@universal/services/message";
import I18nService          from '@universal/services/i18n';

import "./manager.css";
import FilterType, { FilterValueType } from '@universal/types/business/Filter';

type FilterManagerProps = {
  defaultFilters?: Array<FilterType> | null,
  type: string,
  title: string
}

const Manager: FunctionComponent<FilterManagerProps> = (props: FilterManagerProps) => {
  const context       = useContext<ContextType>(CFilter.Context);
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const api           = useService<ApiService>("api");
  const message       = useService<MessageService>("message");
  const i18n          = useService<I18nService>("i18n");
  
  const defaultFilters: Array<FilterType> = props.defaultFilters
    ? props.defaultFilters
    : [{
      name: i18n.translate("filter_manager_default"),
      values: [],
      _id: "see_all_filters"
    }];

  const [filters, setFilters] = React.useState<Array<FilterType>>(defaultFilters);
  const [filterToModify, setFilterToModify] = React.useState<FilterType | null>(null);
  const [displayForm, openFormModal, closeFormModal] = useOpenCloseToggle();

  React.useEffect(() => {
    api.service("filters", "get").execute({ type: props.type, tenant: currentTenant.currentId }, { name: 1 })
    .then((tenantFilters: Array<FilterType>) => {
      context.aggregator.hydrate(tenantFilters.some(f => f.default) 
        ? tenantFilters.find(f => f.default)?.values 
        : defaultFilters[0].values
      );
      setFilters(defaultFilters.concat(tenantFilters));
    });
  }, [currentTenant.currentId]);

  const _onFilterChange = React.useCallback((filterId: string): void => {
    context.aggregator.hydrate(filters.find((f: FilterType )=> f._id === filterId)?.values);
  }, [context, filters]);

  const _orderFilterValue = React.useCallback((filterValue: Array<FilterValueType>): Array<FilterValueType> => {
    return filterValue.sort((f1, f2) => {
      const nameCompare = f1.name.localeCompare(f2.name);
      if(nameCompare !== 0){
        return nameCompare;
      }
      if(_.isString(f1.value) && _.isString(f2.value)){
        return f1.value.localeCompare(f2.value);
      }
      if(_.isBoolean(f1.value) && _.isBoolean(f2.value)){
        return f1.value ? -1 : 1;
      }
      if(_.isInteger(f1.value) && _.isInteger(f2.value)){
        return f1.value - f2.value;
      }
      return 0;
    });
  }, []);

  const getCurrentSelectedFilter = React.useCallback((): FilterType | undefined => {
    const jsonAggregatorValue = JSON.stringify(_orderFilterValue(context.aggregator.deshydrate()));
    return filters.find((f: FilterType) => JSON.stringify(_orderFilterValue(f.values.slice())) === jsonAggregatorValue);
  }, [context, filters, _orderFilterValue]);

  const _popErrorEquivalentFilter = React.useCallback((equivalentFilter: FilterType, type: string): void => {
    message.send("warning", i18n.translateString(`filter_manager_errorEquivalentFilter_${type}`, {
      filter: equivalentFilter.name
    }, true));
  }, [i18n, message]);

  const _onClickToEditFilter = React.useCallback((e: Event, filter: FilterType): void => {
    e.stopPropagation();

    const equivalentFilter = getCurrentSelectedFilter();
    if(!equivalentFilter || equivalentFilter._id === filter._id){
      setFilterToModify(_.pick(filter, ["_id", "name", "default"]))
    }else{
      _popErrorEquivalentFilter(equivalentFilter, "update");
    }
  }, [getCurrentSelectedFilter, setFilterToModify, _popErrorEquivalentFilter]);

  const _onClickToCreate = React.useCallback((e: Event): void => {
    e.stopPropagation();
    const equivalentFilter = getCurrentSelectedFilter();
    if(!equivalentFilter){
      openFormModal();
    }else{
      _popErrorEquivalentFilter(equivalentFilter, "create");
    }
  }, [getCurrentSelectedFilter, openFormModal, _popErrorEquivalentFilter]);

  const _closeForm = React.useCallback((): void => {
    setFilterToModify(null);
    closeFormModal();
  }, [setFilterToModify, closeFormModal]);

  const _onFormCreate = React.useCallback((filter: FilterType): void => {
    setFilters((state: Array<FilterType>) => ([...state, filter]));
  }, [setFilters]);

  const _onFormUpdate = React.useCallback((filter: FilterType): void => {
    setFilters((state: Array<FilterType>) => {
      const filters = state.slice();
      //Si le filtre actuel est paramétré en tant que filtre par défaut
      //MAJ de l'ancien filtre par défaut pour passer sa valeur à { default: false }
      if (filter.default && filters.some(f => f.default)) {
        const currentDefaultFilter = filters.find(f => f.default);
        currentDefaultFilter.default = false;
      }
      //MAJ du filtre 
      const index = filters.findIndex(f => f._id === filter._id);
      filters[index] = filter;
      return filters;
    });
  }, [setFilters]);

  const _onFormDelete = React.useCallback((filterId: string): void => {
    setFilters((state: Array<FilterType>) => state.filter((f: FilterType) => f._id !== filterId));
  }, [setFilters]);


  if(!context.aggregator.isReady()){
    return null;
  }

  const selectedFilter: FilterType = getCurrentSelectedFilter();
  const selectedFilterId: string | null = selectedFilter
    ? selectedFilter._id
    : null;

  const formFilter = filterToModify ? filterToModify : { default: false };

  const { title } = props;

  return (
    <>
      <Collapsable defaultOpen={true}>
        <Collapsable.Title>
            <div className="bs-filters-manager-title">
              <span>{ title }</span>
              <span onClick={ _onClickToCreate } className="bs-filters-add-btn fa fa-plus-circle" />
            </div>
        </Collapsable.Title>
        <Collapsable.Content>
          <div className="bs-filters-manager-content">
            <Input.Radio.BtnFilter onChange={ _onFilterChange } value={ selectedFilterId } >
              {filters.map((filter: FilterType, idx: number) => (
                <Input.Radio.Value key={ filter._id } value={ filter._id }>
                  <div className="bs-filters-manager-items-container">
                    <div>
                      { filter.name }
                      { filter.default && (
                        <span className="bs-filters-manager-defaultFilter fa fa-dot-circle-o" />
                      )}
                    </div>
                    {idx > ( defaultFilters?.length - 1 ) && (
                      <span onClick={e => _onClickToEditFilter(e, filter)} className="fa fa-pencil" />
                    )}
                  </div>
                </Input.Radio.Value>
              ))} 
            </Input.Radio.BtnFilter>
          </div>
        </Collapsable.Content>
      </Collapsable>

      <Display.If condition={ displayForm || filterToModify }>
        <Modal.Show style={{ width: "40vw", height: "40vh" }} close={ _closeForm }>
          <FilterForm 
            aggregator={ context.aggregator }
            type={ props.type }
            onCreated={ _onFormCreate }
            onUpdated={ _onFormUpdate }
            onDeleted={ _onFormDelete }
            default={ formFilter }
          />
        </Modal.Show>
      </Display.If>
    </>
  );
} 

export default Manager;

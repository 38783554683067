// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.bs-test-header {
  display: flex;
  flex-direction: row;
  gap: var(--std-size-inter-element)
}
.bs-test-header:hover {
  cursor: pointer;
}

.bs-test-content {
  padding-left: 25px;
}`, "",{"version":3,"sources":["webpack://./src/views/settings/tools/test/components/test.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB;AACF;AACA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\n\n.bs-test-header {\n  display: flex;\n  flex-direction: row;\n  gap: var(--std-size-inter-element)\n}\n.bs-test-header:hover {\n  cursor: pointer;\n}\n\n.bs-test-content {\n  padding-left: 25px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

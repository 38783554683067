import { Service }  from "@uLib/application";
import StorageService, { GetHandler, HasHandler, RemoveHandler, SetHandler } from '@universal/services/types/storage';

export default class MemoryStorageService extends Service implements StorageService {
  private _storage: Map<string, string>;

  constructor(name: string = "temporaryStorage") {
    super(name);
    this._storage = new Map<string, string>();
  }

   has: HasHandler = async (key: string) => {
    return this._storage.has(key);
  };

  get: GetHandler = async (key: string, parse: boolean = false) => {
    const storedItem = this._storage.get(key);    
    return parse ? JSON.parse(storedItem) : storedItem;
  };

  set: SetHandler = async (key: string, value: string, stringify: boolean = false) => {
    if(stringify){
      value = JSON.stringify(value);
    }
    this._storage.set(key, value);
  };

  remove: RemoveHandler = async (key: string) => {
    this._storage.delete(key);
  };
}
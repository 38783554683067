import Query from "@universal/types/technic/Query"
import SortRule from "@universal/types/technic/Sort"
import PushService from "../push";
import CachedCollection from "@universal/lib/collection/cachedCollection";
import StorageService from "../types/storage";
import NetworkingService from "../types/networking";
import FilteredCollection from "@universal/lib/collection/filteredCollection";
import SortedCollection from "@universal/lib/collection/sortedCollection";
import Key from "@universal/lib/key";
import Collection from "@universal/lib/collection/collection";
import ICollection from "@universal/lib/collection/iCollection";
import md5 from 'md5'
import { Listener } from "@universal/lib/event";
import ICacheContext, { Api, ApiFindHandler } from "./iCacheContext";
import logger from "@universal/lib/logger";
import Sorter from "@universal/lib/sorter";

class ContextAllLoaded<Type> implements ICacheContext<Type> {
  private type: string;

  private pushService: PushService;

  private networkService: NetworkingService;

  private storage: StorageService;

  private api: Api<Type, ApiFindHandler<Type>>;

  private collection: ICollection<Type>;

  private query: Query<Type>;

  private key: Key<Type>;

  private loadPromise: Promise<void>;

  private networkListener: Listener;

  private pushListener: Listener;

  private validateMode: boolean = false;

  constructor(
    type: string,
    pushService: PushService,
    networkService: NetworkingService,
    storage: StorageService,
    api: Api<Type, ApiFindHandler<Type>>,
    key: Key<Type>,
    query: Query<Type>
  ) {
    this.type = type;
    this.pushService = pushService;
    this.networkService = networkService;
    this.storage = storage;
    this.api = api;
    this.key = key;
    this.query = {};
    this.collection = new Collection<Type>(key);
    this.loadPromise = Promise.resolve();
    this.createContext(query);

    this.networkListener = this.networkService.onStateChange.addListener(new Listener(this.onNewtorkStateChange, this));
    this.pushListener = this.pushService.onMessageReceived.addListener(new Listener(this.onMessageReceived, this));
  }

  setValidateMode(validateMode: boolean){
    this.validateMode = validateMode;
  }

  dispose(){
    this.networkService.onStateChange.removeListener(this.networkListener);
    this.pushService.onMessageReceived.removeListener(this.pushListener);
  }

  private onNewtorkStateChange = () => {
    if(this.networkService.isConnected()){
      this.loadAllFromApi();
    }
  }

  private onMessageReceived = (type: string, action: "create" | "update" | "delete", datas: Type[]) => {
    if(this.type === type){
      if(action === 'create' || action === 'update'){
        this.collection.addMany(datas);
      } else if(action === 'delete'){
        this.collection.dropMany(datas);
      }
      this.persist();
    }
  }

  createContext(query: Query<Type>) {
    this.query = query;
    this.collection = new CachedCollection<Type>(
      new FilteredCollection<Type>(
        new SortedCollection<Type>(this.key),
        this.query
      )  
    , 1800000);
    
    this.loadAll();
  }

  private loadAll = async () => {
    if(this.networkService.isConnected()){
      await this.loadAllFromApi();
    } else {
      await this.loadFromStorage();
    }
  }

  private loadAllFromApi(): Promise<void> {
    this.loadPromise = new Promise<void>( async (resolve, reject) => {
      this.collection.clear();
      let result: Type[] = [];
      let offset = 0;
      const limit = 100;
      do {
        result = await this.api.execute(this.query, {}, offset, limit);
        offset += limit;
        this.collection.addMany(result);
      } while(result.length === limit);

      this.persist();

      resolve();
    });
    return this.loadPromise;
  }

  private async loadFromStorage(){
    this.loadPromise = new Promise<void>( async (resolve, reject) => {
      const data: Record<string, Type[]> = (await this.storage.get(this.type, true)) || {};

      const key = md5(JSON.stringify(this.query));
      if(data[key]){
        this.collection.clear();
        this.collection.addMany(data[key]);
      }

      resolve();
    });
  }

  private async persist() {
    const data: Record<string, Type[]> = (await this.storage.get(this.type, true)) || {};

    data[md5(JSON.stringify(this.query))] = this.collection.toArray();
    
    await this.storage.set(this.type, data, true);
  }

  //Améliorer la fonction pour qu'elle soit "générique"
  private async passThrough(results: Type[], query: Query<Type>, sort: SortRule<Type>, offset: number, limit: number): Promise<Type[]> {
    if(!query?._id?.$in || query._id.$in.length === results.length){
      return results;
    }
    const toFind = query._id.$in.filter((id: string) => !results.find(result => (result as { _id: string })._id === id));
    const othersResults = await this.api.execute({ _id: { $in: toFind }}, sort, 0, toFind.length);
    this.collection.addMany(othersResults);
    this.persist();

    return results.concat(othersResults);
  }

  find = async (query: Query<Type> | null, sort: SortRule<Type> | null, offset: number = 0, limit: number = 30): Promise<Type[]> => {
    if(!query){
      query = {};
    }
    
    if(!sort || Object.keys(sort).length === 0){
      sort = this.key.sortRule;
    }
    await this.loadPromise;

    const results = await this.passThrough(
      await this.collection.find(query, sort).slice(offset, limit),
      query,
      sort,
      offset,
      limit
    );

    if(this.validateMode){
      this.validateFind(
        results,
        query,
        Sorter.create<Type>(sort).join(this.collection.sorter).sortRule,
        offset,
        limit
      );
    }
    
    return results;
  }

  private async validateFind(results: Type[], query: Query<Type>, sort: SortRule<Type>, offset: number, limit: number): Promise<void> {
    const apiResults = await this.api.execute(query, sort, offset, limit);
    let valid = apiResults.length === results.length;
    for(let i = 0; i < results.length && valid; i++){
      valid = (results[i] as { _id: string })._id === (apiResults[i] as { _id: string })._id;
    }

    if(!valid){
      logger.error("Data inconsistency with '", this.type, "'\nquery: ", query, "\nsort : ", sort, "\ncache: ", results, "\napi: ", apiResults);
    }else {
      logger.info("Data consistency with '", this.type, "'\nquery: ", query, "\nsort : ", sort);
    }
  }

  //Améliorer la fonction pour qu'elle soit "générique"
  get = async (id: string): Promise<Type> => {
    await this.loadPromise;
    const key = this.key.valuesToQuery([id]);
    if(!this.collection.has(key)){
      const result = await this.api.execute({ _id: { $in: [id] }}, {}, 0, 1);
      this.collection.addMany(result);
      this.persist();
    }
    return this.collection.get(key);
  }

  count = async (query: Query<Type> | null): Promise<number> => {
    if(!query){
      query = {};
    }
    await this.loadPromise;
    return this.collection.find(query, {}).length;
  }
}

export default ContextAllLoaded;
import Type          from './type';
import Building      from './building'; 
import SubBuilding   from './subBuilding'; 
import Equipment     from './equipment';
import Localities    from './localities';
import Followed      from './followed';
import CreatedAt     from './createdAt';
import Category      from './category';
import Manager       from './manager';
import Tag           from './tag';
import Team          from './team';
import Agent         from './agent';
import CreatorType   from './creatorType';
import RequestorType from './requestorType';
import State         from './state';
import Deadline from './deadline';

export default {
  Type,
  Building,
  SubBuilding,
  Equipment,
  Localities,
  Followed,
  CreatedAt,
  Category,
  Manager,
  Tag,
  Team,
  Agent,
  CreatorType,
  RequestorType,
  State,
  Deadline
};
import CollectionStrategy from './strategyCollection';
import Criterion, { Filter } from '../filter';
import Query from "@universal/types/technic/Query";
import SortRule from "@universal/types/technic/Sort";
import ICollection from './iCollection';
import Sorter from '../sorter';
import { combinateQueryOrQueryWithOptimizer, getQueryIfOptimized } from '../query';

class FilteredCollection<Type> extends CollectionStrategy<Type> {
  private filter: Filter<Type>;

  private _query: Query<Type>;

  constructor(collection: ICollection<Type>, query: Query<Type>) {
    super(collection);
    this._query = query;
    this.filter = Criterion.factory<Type>(getQueryIfOptimized<Type>(query) || {});
    const toRemove: Type[] = [];
    for(const item of collection) {
      if(!this.filter.match(item)) {
        toRemove.push(item);
      }
    }
    if(toRemove.length) {
      collection.dropMany(toRemove);
    }
  }

  add(item: Type): void {
    const accepted = this.filter.match(item);
    if(this.collection.has(item) && !accepted) {
      this.collection.drop(item);
    } else if(accepted) {
      this.collection.add(item);
    }
  }

  addMany(items: Iterable<Type>): void {
    const toAdd: Type[] = [];
    const toDrop: Type[] = [];
    for(const item of items) {
      const accepted = this.isConcerned(item);
      if(this.collection.has(item) && !accepted) {
        toDrop.push(item);
      } else if(accepted) {
        toAdd.push(item);
      }
    }
    if(toDrop.length) {
      this.collection.dropMany(toDrop);
    }
    if(toAdd.length) {
      this.collection.addMany(toAdd);
    }
  }

  isConcerned(item: Type): boolean {
    return this.filter.match(item);
  }

  find(query: Query<Type>, sort: SortRule<Type> | Sorter<Type>): ICollection<Type> {
    const collection = this.collection.find(query, sort);
    return new FilteredCollection(collection, combinateQueryOrQueryWithOptimizer("$and", this._query, query));
  }

  get query(): Query<Type> {
    return this._query;
  }
}

export default FilteredCollection;
import Application from "@universal/lib/application";
import Tenant from "@universal/types/business/Tenant";
import { LoggableUser } from "@universal/types/business/User";
import { StartHandler } from "./createApplication";
import ApiService from "@universal/services/api";
import validatePPManagerAccount from "./validatePPManagerAccount";
import { TestBuilder } from "./test";
import createMainUser from "./PPcreateMainUser";


type StepCreation = { appAdmin: Application, appPP: Application, appMainPP: Application, tenant: Tenant };

type GetPPTechnicalUserHandler = (appTest: Application) => Promise<{ user: LoggableUser, tenant: Tenant}>;

type CreateApplicationHandler =  (getPPTechnicalUser: GetPPTechnicalUserHandler) => () => Promise<StepCreation>;

export default (title: string, getPPTechnicalUser: GetPPTechnicalUserHandler) => (createApplication: CreateApplicationHandler, createValidationApp: (beforeStart: StartHandler) => Promise<Application>) => {
  return TestBuilder.createTest("PanneauPocket", title)
    .addStep("Préparation de l'application", createApplication(getPPTechnicalUser))
    .addStep("Création d'un compte utilisateur principale", async ({ appPP, tenant, appAdmin, appMainPP }: StepCreation) => {
      
      await createMainUser(appPP, appAdmin);
      
      const result = await appMainPP.getService<ApiService>("api").service("tenants", "panneaupocketInfo").execute(tenant.informations.siren);
      if(result.managerState !== "notValidated"){
        throw "La structure n'est pas en attente de validation du compte manager";
      }
      return {
        appMainPP,
        appAdmin,
        tenant
      }
    })
    .addStep("Validation de l'utilisateur principale créé", validatePPManagerAccount(createValidationApp))
    .build();
};
import Tenant from "@universal/types/business/Tenant";

function getRandomInt(min: number, max: number):number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function generateTable(maxValue: number, minNbr:number, maxNbr: number, inc: number): number[]{
  const nbr = maxNbr !== minNbr
    ? getRandomInt(minNbr, maxNbr)
    : maxNbr;

  const table: number[] = [];
  for(let i = 0; i < nbr; ++i){
    table.push(getRandomInt(0, maxValue) + inc);
  }
  return table;
}

function getRandomTableBySize(tables: number[][]): number[] {
  const total = tables.reduce((total, table) => total + table.length, 0);
  const value = getRandomInt(0, total - 1);
  let inc = 0;
  return tables.find(table => {
    inc += table.length;
    return value < inc;
  })
}

function shakeTables(...tables: number[][]): number[] {
  const results: number[] = [];
  const total = tables.reduce((total, table) => total + table.length, 0);
  for(let i = 0; i < total; ++i){
    tables = tables.filter(table => table.length);
    results.push((getRandomTableBySize(tables).pop()) as number);
  }
  return results;
}

function generatePassword(size: number): string {
  const majuscules = generateTable(25, 1, size - 2, 65);
  const minucules = generateTable(25, 1, size - majuscules.length - 1, 97);
  const nbrNumericsNeeds = size - majuscules.length - minucules.length;
  const numerics   = generateTable(9, nbrNumericsNeeds, nbrNumericsNeeds, 48);

  return shakeTables(majuscules, minucules, numerics)
    .reduce((password, char) => password + String.fromCharCode(char), '');
}

const appCodeToName = {
  "jvs-ac": "Attractive City",
  "jvs-gs": "Logiciel gestion des salles",
  "jvs-pp": "Panneau Pocket",
  "tablet": "Tablette"
}

export type AppName = keyof typeof appCodeToName;

export const generateUsername = (tenant: Tenant, application: AppName) => {
  return `${tenant.name.fr.toLocaleLowerCase()}_${application.toLocaleLowerCase()}`;
}

export const generateFullname = (tenant: Tenant, application: AppName) => {
  return `${appCodeToName[application]} ${tenant.name.fr}`;
}

export default generatePassword;
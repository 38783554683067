import ApiService from "@universal/services/api";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import RepositoryService from "@universal/services/repository";
import Category from "@universal/types/business/Category";


type StepCreation = { application: Application };

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("AttractiveCity", "Création d'un signalement")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Récupération d'une catégorie", async ({ application }: StepCreation) => {     
      const repository = application.getService<RepositoryService>("repository").get("Category").repository;
      let categories = await repository.find({}, {}) as Category[];
      if(categories.length === 0){
        throw "Aucune catégorie trouvée";
      }
      return { 
        application,
        category: categories[0]
      };
    })
    .addStep("Création d'un signalement", async ({ application, category }: { application: Application, category: Category }) => {
      const issue = await application.getService<ApiService>("api").service("issues", "post").execute({
        requestor: {
          type: "citizen",
          firstname: "Faux",
          lastname: "Utilisateur",
          email: "faux.utilisateur@faux.domaine"
        },
        files: [],
        description: "Test Attractive City",
        discriminator: "publicSpace",
        location: {
          address: {
            streetNumber: "20",
            street: "Guido Gezellestraat",
            locality: "Aalter",
            country: "BE",
            zip: "9880"
          },
          position: {
            type: "Point",
            coordinates:[ 3.4454584121704106, 51.040105422904276]
          }
        },
        category: category._id
      });


    })
    .build();
};
import React, { FunctionComponent } from 'react';
import { ITest, TestSet } from '../library/test';
import Test from '../components/test';
import Button from '@common/components/button';


import './set.css';

const LaunchButton = Button.withStyle(Button.Stylized.positive)

type SetProps = {
  testSet: TestSet | null;
}

const Set: FunctionComponent<SetProps> = ({ testSet }) => {

  const testsByNamespace = React.useMemo(() => {
    if(!testSet){
      return {};
    }
    return testSet.getTests()
      .sort((test1, test2) => test1.namespace.localeCompare(test2.namespace))
      .reduce<Record<string, ITest[]>>((testsByNamespace: Record<string, ITest[]>, test: ITest) => {
        if(!testsByNamespace[test.namespace]){
          testsByNamespace[test.namespace] = [];
        }
        testsByNamespace[test.namespace].push(test);
        return testsByNamespace;
      }, {});    
  }, [testSet]);

  const launchTest = React.useCallback(() => {
    if(!testSet){
      return;
    }
    testSet.run();
  }, [testSet]);

  return (
    <div className='bs-test-set'>
      <div><LaunchButton onClick={ launchTest }>Lancer les tests</LaunchButton></div>
      {
        Object.keys(testsByNamespace).map((nameSpace: string) => {
          const tests = testsByNamespace[nameSpace];

          return (
            <div key={ nameSpace } className='bs-test-namespace'>
              <div className='bs-test-namespace-title'>{ nameSpace }</div>
              <div className='bs-test-namespace-content'>
              {
                tests.map((test, index) => (
                  <Test key={ index } test={ test } /> 
                ))
              }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default Set;
import React from "react";
import T from "@uBehaviour/i18n";
import List from "./list";
import dayjs from "dayjs";
import IssueItem  from "@entities/issue/item/short";
import AssignmentItem  from "@entities/assignments/item";
import { Route, useLocation }     from '@cFeatures/router';
import { Link } from '@cFeatures/router';
import Filter from "@uComponents/filter";
import Application from "@uBehaviour/application";
import Modal from "@cComponents/modal";
import Display from "@uComponents/displayIf";
import Input from "@cComponents/input";
import Weather from "./weather";
import { joinWithOptimizer } from '@uLib/query';
import useService from '@uBehaviour/hooks/useService';
import usePager from '@uBehaviour/data/hooks/usePager';
import _ from "lodash";
import DataTestId, { useDataTestId } from "@universal/features/dataTestId";

import "./home.css";
import { IssueDetail } from "../issues";

const ModalFilterManager = ({ value, close, clear, add, drop }) => { 
  const currentTenant = useService("currentTenant");
  const { datas: managers, allElementsLoaded } = usePager({
    model: "User",
    query : { tenants: { $elemMatch: { tenant: currentTenant.currentId, roles: { $in: ["admin", "manager", "categoryManager"]}}}},
    sort: { fullname: -1 },
    loadAll: true
  });

  // DataTestIDs
  const filterManagerModalTestId  = useDataTestId("filterManager-modal");
  const filterManagerNameTestId   = `info-${useDataTestId("filterManager-name")}`;
  const filterManagerClearTestId  = `inter-${useDataTestId("filterManager-clear")}`;

  if (!allElementsLoaded) {
    return null;
  }
  return (
    <Modal.Show close={close}>
      <div className="bs-home-filter" data-testid={filterManagerModalTestId}>
        <div className="bs-home-filter-header"><T>home_filter_header</T></div>
        <div className="bs-home-filter-content">
          <div className="bs-home-filter-action" onClick={ clear } data-testid={filterManagerClearTestId}>Tous</div>
          <Input.Checkbox
            value={ value }
            onChange={(values, value, check) => { if (check) add(value); else drop(value); }}
            layout={<Input.Checkbox.Layout.Table column={3}/>}
          >
          {
            managers?.map(manager => (
              <Input.Checkbox.Value key={ manager._id } value={ manager._id }>
              {(selected) => (
                <div>
                  <span className={`bs-home-filter-manager${selected ? " bs-selected" : ""}`}>
                    <span style={{ marginRight: selected ? "2px" : "4.28px"}} className={ `fa fa-${ selected ? "check-" : ""}square-o` }/>
                    <span data-testid={filterManagerNameTestId}>{ manager.fullname }</span>
                  </span>
                </div>
              )}
              </Input.Checkbox.Value>
            ))
          }
          </Input.Checkbox>
        </div>
      </div>
    </Modal.Show>
  )
};

const Home = Application.Service.forward(["currentTenant"], ({ currentTenant }) => {
  const [now, setNow] = React.useState(dayjs());
  const [displayFilter, setDisplayFilter] = React.useState(false);

  const refresh     = React.useCallback(_.debounce(() => setNow(dayjs()), 1000), [setNow]);
  const openFilter  = React.useCallback(() => setDisplayFilter(true), [setDisplayFilter]);
  const closeFilter = React.useCallback(() => setDisplayFilter(false), [setDisplayFilter]);

  // DataTestIDs
  const filterManagerRefreshTestId = `inter-${ useDataTestId("home-action-filterManager-refresh")}`;
  const filterManagerDisplayTestId = `inter-${ useDataTestId("home-action-filterManager-display")}`;

  return (
    <Filter.Aggregator>
      <div className="bs-home">
        <div className="bs-home-header">
          <div>
            <span className="fa fa-refresh bs-action" onClick={ refresh } data-testid={ filterManagerRefreshTestId } />
            <span className="bs-action" onClick={ openFilter } data-testid={ filterManagerDisplayTestId }><T>home_filter_header</T></span>
            <Display.If condition={displayFilter}>
              <Filter.Generic
                name="manager"
                multiple
                buildQuery={values => ({ "manager": { $in: values } })}
              >
              {(value, add, drop, clear) => ( 
                <ModalFilterManager close={ closeFilter } value={value} clear={clear} add={add} drop={drop} />
              )}
              </Filter.Generic>
            </Display.If>
          </div>
        </div>
        <div className="bs-home-content">
          <div className="bs-home-lists">
            <Filter.Subject>
            {composeQuery => {

              const lastIssuesQuery = composeQuery({ 
                tenant: currentTenant.currentId, 
                state: "open"
              });

              const nearingDeadlineQuery = composeQuery({ 
                tenant: currentTenant.currentId, 
                $and: [
                  { deadline: { $gte: now.startOf("day").toISOString() }},
                  { pc_deadlineReminder: { $lte: now.startOf("day").toISOString() }}
                ],
                state: { $in: ["open", "in_progress", "planned"]}
              });

              const exceededDeadlineQuery = composeQuery({
                tenant: currentTenant.currentId,
                $and: [
                  { deadline: { $ne: null }},
                  { deadline: { $lt: now.endOf("day").toISOString() }},
                ],
                state: { $in: ["open", "in_progress", "planned"]}
              });

              const assignmentQuery = joinWithOptimizer(
                composeQuery({ "assignment.scheduledFrom": { $gte: now.endOf("day").toISOString() }, tenant: currentTenant.currentId }),
                { tenant: currentTenant.currentId }
              );

              return (
                <>
                  <DataTestId.Prefix value="lastIssues">
                      <List query={ lastIssuesQuery } sort={{ createdAt: -1 }} dateField="createdAt" title={<T>home_list_lastIssues</T>} model="Issue" load={ IssueItem.load }>
                      {(data, { index }) => (
                        <DataTestId.Prefix value={ index + 1 }>
                          <Link to={ `/issues/${ data._id }`} key={ data._id }>
                              <IssueItem data={data}/>
                          </Link>
                        </DataTestId.Prefix>
                      )}
                      </List>
                  </DataTestId.Prefix>
                  <DataTestId.Prefix value="nextScheduled">
                    <List query={ assignmentQuery } sort={{ "assignment.scheduledFrom": 1 }} dateField="assignment.scheduledFrom" title={<T>home_list_nextScheduled</T>} model="Assignment" load={ AssignmentItem.Short.load }>
                    {(data, { index }) => (
                      <DataTestId.Prefix value={ index + 1 }>
                        <Link to={ `/assignments/${ data.issue }`} key={ data._id }>
                            <AssignmentItem.Short data={data}/>
                        </Link>
                      </DataTestId.Prefix>
                    )}
                    </List>
                  </DataTestId.Prefix>
                  <DataTestId.Prefix value="nearingDeadline">
                    <List color="rgb(223, 150, 32)" query={ nearingDeadlineQuery } sort={{ deadline: 1 }} dateField="deadline" title={<T>home_list_comingToEndSoon</T>} model="Issue" load={ IssueItem.load }>
                    {(data, { index }) => (
                      <DataTestId.Prefix value={ index + 1 }>
                        <Link to={ `/issues/${ data._id }`} key={ data._id }>
                            <IssueItem data={data}/>
                        </Link>
                      </DataTestId.Prefix>
                    )}
                    </List>
                  </DataTestId.Prefix>
                  <DataTestId.Prefix value="exceededDeadline">
                    <List color="rgb(223, 32, 32)" query={ exceededDeadlineQuery } sort={{ deadline: 1 }} dateField="deadline" title={<T>home_list_exceededDeadline</T>} model="Issue" load={ IssueItem.load }>
                    {(data, { index }) => (
                      <DataTestId.Prefix value={ index + 1 }>
                        <Link to={ `/issues/${ data._id }`} key={ data._id }>
                            <IssueItem data={data}/>
                        </Link>
                      </DataTestId.Prefix>
                    )}
                    </List>
                  </DataTestId.Prefix>
                </>
              );
            }}
            </Filter.Subject>
          </div>
          <div>
            <Weather />
          </div>
        </div>
      </div>
      <Route path="/issues/:id" component={ IssueDetail } />
      <Route path="/assignments/:id" component={ IssueDetail } />
    </Filter.Aggregator>
  );
})

export default Home;
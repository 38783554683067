import SortRule from "@universal/types/technic/Sort";
import Collection from "./collection";
import Key from "../key";
import Sorter from "../sorter";
import Query from "@universal/types/technic/Query";
import ICollection from "./iCollection";

class SortedCollection<Type> extends Collection<Type> {
  constructor(key: Key<Type>, sortRule: SortRule<Type> | Sorter<Type> | null = null) {
    super(key);
    this._sorter = sortRule ? Sorter.create<Type>(sortRule).join(this.sorter) : this.sorter;
  }

  find(query: Query<Type>, sortRule: SortRule<Type> | Sorter<Type>): ICollection<Type> {
    const collection = new SortedCollection(this.key, Sorter.create<Type>(sortRule).join(this.sorter));
    collection.addMany(super.find(query, sortRule));
    return collection;
  }
  
}

export default SortedCollection;
import { Service }  from "@uLib/application";
import Event, { Listener } from "@uLib/event";
import Push         from "@uLib/pushClient";

export default class PushService extends Service{
  
  constructor(loadSockedIo){
    super("push", ["configuration", "repository", "session", "networking"]);
    this._pushClient          = null;
    this._sessionListener     = new Listener(this.onStateChange, this);
    this._pushClientListener  = new Listener(this.onMessageReceivedOnSocket, this);
    this._loadSockedIo        = loadSockedIo;
    this._onMessageReceived   = new Event();
  }
  get onMessageReceived(){
    return this._onMessageReceived;
  }
  get client(){
    return this._pushClient;
  }
  start(){
    return this.waitReady(["configuration", "session", "networking"]).then(([configuration, session, networking]) => {     
      const socketIOUri = configuration.get("socketio_uri");
      return this._loadSockedIo(socketIOUri).then(io => {
        this._pushClient = new Push.Client(io, socketIOUri);
        this._pushClient.onReceipt.addListener(this._pushClientListener);
        session.onServiceUpdated.addListener(this._sessionListener);
        networking.onConnect.addListener(({handleEvent: () => this.onStateChange(session)}));
        networking.onDisconnect.addListener(({handleEvent: () => {
          if (this._pushClient.isConnected()) {
            this._pushClient.token = null;
            this._pushClient.disconnect();
          }
        }}));
        this.onStateChange(session);
      });
    });
  }
  onStateChange(session){
    if(session.isLogged()){
      if (!this._pushClient.isConnected()) {
        this._pushClient.token = session.token;
        this._pushClient.connect();
      }      
    }else{
      if (this._pushClient.isConnected()) {
        this._pushClient.token = null;
        this._pushClient.disconnect();
      }      
    }
  }
  onMessageReceivedOnSocket(type, action, datas){
    const repository = this.application.getService("repository");
    repository.update(type, action, datas);
    this._onMessageReceived.trigger(type, action, datas);
  }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-test-state-running,
.bs-test-state-pending {
  color: var(--std-color-gray);
}

.bs-test-state-success {
  color: var(--std-color-green);
}

.bs-test-state-error {
  color: var(--std-color-red);
}`, "",{"version":3,"sources":["webpack://./src/views/settings/tools/test/components/state.css"],"names":[],"mappings":"AAAA;;EAEE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".bs-test-state-running,\n.bs-test-state-pending {\n  color: var(--std-color-gray);\n}\n\n.bs-test-state-success {\n  color: var(--std-color-green);\n}\n\n.bs-test-state-error {\n  color: var(--std-color-red);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;


import Application from "@universal/lib/application";
import PPsearchTechnicalUserAndTenant from "../library/PPsearchTechnicalUserAndTenant";
import { LoggableUser } from "@universal/types/business/User";
import Tenant from "@universal/types/business/Tenant";
import { TestBuilder } from "../library/test";
import { getRandomInt } from "@root/old/lib/tool";
import ApiService from "@universal/services/api";
import generateRandomelyString from "../library/generateRandomelyString";
import createMainUser from "../library/PPcreateMainUser";

type StepCreation = { appAdmin: Application, appPP: Application, appMainPP: Application, tenant: Tenant };

type GetPPTechnicalUserHandler = (appTest: Application) => Promise<{ user: LoggableUser, tenant: Tenant }>;

type CreateApplicationHandler =  (getPPTechnicalUser: GetPPTechnicalUserHandler) => () => Promise<StepCreation>;


const createUserOnStarter = (title: string, getPPTechnicalUser: GetPPTechnicalUserHandler) => (createApplication: CreateApplicationHandler) => {
  return TestBuilder.createTest("PanneauPocket", title)
    .addStep("Préparation de l'application", createApplication(getPPTechnicalUser))
    .addStep("Création d'un compte utilisateur principale", async ({ appPP, appAdmin }: StepCreation) => {
      try {
        
        await createMainUser(appPP, appAdmin);
        throw "L'utilisateur a été créé alors que la structure ne devrait pas le permettre";
      } catch (error) {
        return;
      }
    })
    .build();
};


export default createUserOnStarter("Création d'un utilisateur manager sur une structre expert", PPsearchTechnicalUserAndTenant({
  "settings.commercialOffer": "expert"
}));


import Query from "@universal/types/technic/Query";
import ICollection from "./iCollection";
import SortRule from "@universal/types/technic/Sort";
import Sorter from "../sorter";

class StrategyCollection<Type> implements ICollection<Type> {
  protected collection: ICollection<Type>;

  constructor(collection: ICollection<Type>) {
    this.collection = collection;
  }

  [Symbol.iterator](): Iterator<Type, any, any> {
    throw new Error("Method not implemented.");
  }
  clear(): void {
    this.collection.clear();
  }

  has(item: Type): boolean {
    return this.collection.has(item);
  }

  add(item: Type): void {
    this.collection.add(item);
  }

  addMany(items: Iterable<Type>): void {
    this.collection.addMany(items);
  }

  drop(item: Type): void {
    this.collection.drop(item);
  }

  dropMany(items: Iterable<Type>): void {
    this.collection.dropMany(items);
  }

  toArray(): Array<Type> {
    return this.collection.toArray();
  }

  get(object: Partial<Type>): Type {
    return this.collection.get(object);
  }

  forEach(callback: (item: Type) => void): void {
    this.collection.forEach(callback);
  }

  map<NewType>(callback: (item: Type) => NewType): Array<NewType> {
    return this.collection.map(callback);
  }

  slice(offset: number, limit: number): Array<Type> {
    return this.collection.slice(offset, limit);
  }
  
  find(query: Query<Type>, sort: SortRule<Type>): ICollection<Type> {
    return this.collection.find(query, sort);
  }

  isInBound(item: Type): boolean {
    return this.collection.isInBound(item);
  }

  get length(): number {
    return this.collection.length;
  }

  get sorter(): Sorter<Type> {
    return this.collection.sorter
  }
}

export default StrategyCollection;
import Display from '@common/components/displayIf';
import Modal from '@common/components/modal';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import React, { FunctionComponent } from 'react';
import Form from './form';
import FormSimple from '@uBehaviour/form/simple';
import T from '@universal/behaviour/i18n';
import Section from "@cComponents/section";

import './newEntry.css';
import Button from '@common/components/button';
import Layout from '@common/components/layout';

interface FormModalProps {
  close: () => void;
}

const FormModal: FunctionComponent<FormModalProps> = ({ close }) => {
  const form = React.useRef<FormSimple>();

  const submitForm = React.useCallback(() => {
    form.current.submit().then((succeeded ) => {
     
      if (succeeded !== null ) {
        close(); 
      }
    });
  }, [form, close]);
  return (
    <Layout.Standart stretch>
      <Layout.Standart.Header>
        <Section.Header>
          <b><T>issues_newEntry_formModal_title</T></b>
        </Section.Header>
      </Layout.Standart.Header>
      <Layout.Standart.Content>
        <Form ref={ form }/>
      </Layout.Standart.Content>
      <Layout.Standart.Footer>
        <Section.Footer>
          <div className="bs-issue-newEntry-form-footer">
            <Button.Text onClick={ close }><T>issues_newEntry_formModal_cancel</T></Button.Text>
            <Button.Text onClick={ submitForm }><T>issues_newEntry_formModal_submit</T></Button.Text>
          </div>
        </Section.Footer>
      </Layout.Standart.Footer>
    </Layout.Standart>
  );
}

interface NewEntryProps {
  history?: {
    location: {
      pathname: string
    }
  }
}


export default FormModal;
import React          from "react";
import SelectableList from "@cComponents/selectableList";
import User           from "@entities/user";
import Application    from '@uBehaviour/application';
import T              from "@uBehaviour/i18n";
import Button         from "@cComponents/button";

import './defaultBulkUpdateForm.css';
import './managerForm.css';

export default Application.Service.forward(["currentTenant"], ({ submit, close, currentTenant }) => {
  const selectableListRef = React.createRef();

  const lSubmit = React.useCallback(() => {
    const manager = selectableListRef.current.selectedValues[0]._id;
    submit(manager).then(() => close());
  }, [submit, close, selectableListRef.current?.selectedValues]);

  return (
    <div className="bs-issue-list-bulkUpdate-form-manager">
      <div className="bs-issue-list-bulkUpdate-form-header">
        <T>issue_list_groupManager_modal_title</T>
      </div>
      <div className="bs-issue-list-bulkUpdate-form-manager-content">
        <SelectableList
          ref={selectableListRef}
          filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
          limit={1}
          listHeight={"calc(100% - 27px"}
          query={{ tenants: { $elemMatch: { tenant: currentTenant.currentId, roles: { $in: ["categoryManager", "manager", "admin"]}, disabled:false}} }}
          sort={{ fullname: 1 }}
          load={{ avatar:true }}
          model={"User"}
        >
          {data => (
            <User.Item data={data} />
          )}
        </SelectableList>
      </div>
      <div className="bs-issue-list-bulkUpdate-form-footer">
        <Button.Text onClick={ close }><T>issue_list_group_form_modal_cancel</T></Button.Text>
        <Button.Text onClick={ lSubmit }><T>issue_list_group_form_modal_submit</T></Button.Text>
      </div>
    </div>
  );
})

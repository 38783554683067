import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import Display        from '@uComponents/displayIf';      
import InputPeriod    from "@cComponents/input/period";
import moment         from "moment";

const buildQueryPeriod = (value) => {
  let period;
  
  switch(value.type){
    case "outdated":
      period = { $lt: moment().startOf("day").toISOString() };
      break;
    case "today":
      period = { $gte: moment().startOf("day").toISOString(), $lt: moment().startOf("day").add(1, "day").toISOString() };
      break;
    case "tomorrow":
      period = { $gte: moment().startOf("day").add(1, "day").toISOString(), $lt: moment().startOf("day").add(2, "day").toISOString() };
      break;
    case "next_7_days":
      period = { $gte: moment().startOf("day").add(1, "day").toISOString(), $lt: moment().startOf("day").add(8, "day").toISOString() };
      break;
    case "custom_date":
      if(value.start && value.end){
        period = { $gte: moment(value.start).toISOString(), $lt: moment(value.end).toISOString() };
      }else{
        period = null;
      }
      break;
  }
  return period;
}

const stringifyPeriod = (value) => {
  if(value.type !== "custom_date"){
    return (<T>{`filter_deadline_${value.type}`}</T>);
  }else{
    if(value.start && value.end){
      return (<T bind={{ start: moment(value.start).format("DD/MM/YYYY"), end: moment(value.end).subtract(1, "day").format("DD/MM/YYYY")}}>filter_deadline_custom_date_stringify</T>);
    }else{
      return (<T>filter_deadline_custom_date</T>);
    }
  }
}

const Deadline = () => {
  return (
    <Filter.Generic 
        deshydrate={({ type, start, end }) => ({ type, start: start ? moment(start).toISOString(): null, end: end ? moment(end).toISOString() : null }) }
        hydrate={ 
          values => values.reduce((map, value) => { 
            map.set(value, { type: value.type, start: value.start ? moment(value.start).toDate() : null, end: value.end ? moment(value.end).toDate() : null });
            return map
          }, new Map())
        }
        name="deadline" 
        buildQuery={value => ({deadline: buildQueryPeriod(value)})}
        stringify={value => (<><T>filter_deadline_scheduled</T> : { stringifyPeriod(value) }</>)}
      >
      {(value, set, clear) => (
        <Collapsable>
          <Collapsable.Title><T>filter_deadline</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Radio.BtnFilter onChange={(value) => { set({ type: value }); }} value={value && value.type}>
              <Input.Radio.Value value={"outdated"}><T>filter_deadline_outdated</T></Input.Radio.Value>
              <Input.Radio.Value value={"today"}><T>filter_deadline_today</T></Input.Radio.Value>
              <Input.Radio.Value value={"tomorrow"}><T>filter_deadline_tomorrow</T></Input.Radio.Value>
              <Input.Radio.Value value={"next_7_days"}><T>filter_deadline_next_7_days</T></Input.Radio.Value>
              <Input.Radio.Value value={"custom_date"}><T>filter_deadline_custom_date</T></Input.Radio.Value>
            </Input.Radio.BtnFilter>
            <Display.If condition={ value && value.type === "custom_date"}>
              <div>
                <InputPeriod
                  allDay
                  start={value && value.start }
                  end={value && value.end }
                  onChange={ (start, end) =>  set({ type: "custom_date", start, end }) }
                >
                  <InputPeriod.StartLabel>
                    <T>filter_deadline_input_period_start_label</T>
                  </InputPeriod.StartLabel>
                  <InputPeriod.EndLabel>
                    <T>filter_deadline_input_period_end_label</T>
                  </InputPeriod.EndLabel>
                </InputPeriod>
              </div>
            </Display.If>
          </Collapsable.Content>
        </Collapsable>
      )}
      </Filter.Generic>
  )
}

export default Deadline;
import Event from "@universal/lib/event";


export enum State {
  PENDING,
  RUNNING,
  SUCCESS,
  ERROR,
};

export interface IState {
  readonly state: State;
  readonly onStateChange: Event;
}

export interface IStep extends IState {
  readonly description: string;
  readonly error: any;
}

export interface ITest extends IState {
  readonly description: string;
  readonly namespace: string;
  getSteps(): IStep[];
}


export class TestSet {
  private tests: Test[];

  private beforeEachHandler: () => Promise<void>;
  private afterEachHandler: () => Promise<void>;

  constructor() {
    this.tests = [];
    this.beforeEachHandler = async () => {};
    this.afterEachHandler = async () => {};
  }

  init(){
    this.tests.forEach(test => test.init());
  }

  addTest(test: Test) {
    this.tests.push(test);
  }

  getTests(): ITest[] {
    return this.tests.slice() as unknown as ITest[];
  }

  setBeforeEach(handler: () => Promise<void>){
    this.beforeEachHandler = handler;
  }

  setAfterEach(handler: () => Promise<void>){
    this.afterEachHandler = handler;
  }

  async run() {
    this.init();
    for await (let test of this.tests) {
      await this.beforeEachHandler();
      await test.run();
      await this.afterEachHandler();
    }
  }
}

class Test implements ITest {
  private _description: string;

  private steps: Step[];

  private _state: State;

  private stateChangeEvent: Event;

  private _namespace: string;

  constructor(namespace: string, description: string) {
    this._namespace = namespace;
    this._description = description;
    this.steps = [];
    this._state = State.PENDING;
    this.stateChangeEvent = new Event();
    this.init();
  }

  init() {
    this.steps.forEach(step => step.init());
    this.state = State.PENDING;
  }

  get namespace() {
    return this._namespace;
  }

  get description() {
    return this._description;
  }

  get state() {
    return this._state;
  }

  private set state(value: State) {
    if(this._state !== value) {
      this._state = value;
      this.stateChangeEvent.trigger(this);
    }
  }

  get onStateChange() {
    return this.stateChangeEvent;
  }

  addStep(step: Step) {
    this.steps.push(step);
  }

  getSteps(): IStep[] {
    return this.steps.slice();
  }


  async run() {
    this.state = State.RUNNING;
    let ctx = {};
    try {
      for await (let step of this.steps) {
        ctx = await step.run(ctx);
      }
      this.state = State.SUCCESS;
    } catch (e) {
      this.state = State.ERROR;
    }
  }
}

type StepHandler = (ctx: any) => Promise<any>;

class Step implements IStep {
  private _description: string;

  private handler: StepHandler;

  private _state: State;

  private stateChangeEvent: Event;

  private _error: any;

  constructor(description: string, handler: StepHandler) {
    this._description = description;
    this.handler = handler;
    this._state = State.PENDING;
    this.stateChangeEvent = new Event();
    this.init();
  }

  init() {
    this.state = State.PENDING;
  }

  get description() {
    return this._description;
  }

  get state() {
    return this._state;
  }

  private set state(value: State) {
    if(this._state !== value) {
      this._state = value;
      this.stateChangeEvent.trigger(this);
    }
  }

  get onStateChange() {
    return this.stateChangeEvent;
  }

  get error() {
    return this._error;
  }

  async run(ctx: any) {
    this.state = State.RUNNING;
    try {
      ctx = await this.handler(ctx);
      this.state = State.SUCCESS;
    } catch (e) {
      this._error = e;
      this.state = State.ERROR;
      throw e;
    }
    return ctx;
  }
}

export class TestBuilder {
  static createTest(namespace: string, description: string): TestBuilder {
    return new TestBuilder(namespace, description);
  }

  private test: Test;

  constructor(namespace: string, description: string) {
    this.test = new Test(namespace, description);
  }

  addStep(description: string, handler: StepHandler) {
    this.test.addStep(new Step(description, handler));
    return this;
  }

  build() {
    return this.test;
  }
}
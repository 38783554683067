import Event, { Listener } from "../event";

class DelayCacheUnit<Type> {
  private _delayArrived: Event;

  private _delay: number;

  private timeOutId: number | null;

  private _data: Type;

  private _key: string;

  constructor(key: string, delay: number, data: Type) {
    this._key = key;
    this._delay = delay;
    this._data = data;
    this._delayArrived = new Event();
    this.timeOutId = null;
  }

  get key(): string {
    return this._key;
  }

  private resetTimeout() {
    if (this.timeOutId !== null) {
      clearTimeout(this.timeOutId);
    }

    this.timeOutId = setTimeout(() => {
      this._delayArrived.trigger(this);
    }, this._delay);
  }

  get delayArrived(): Event {
    return this._delayArrived;
  }

  get dataWithoutDelay(): Type {
    return this._data;
  }

  get data(): Type {
    this.resetTimeout();
    return this._data;
  }

  set data(data: Type) {
    this._data = data;
  }
}

class DelayCache<Type>  implements Iterable<DelayCacheUnit<Type>>{
  private cache: Map<string, DelayCacheUnit<Type>>;
  private listeners: Map<string, Listener>;
  
  private delay: number;

  constructor(delay: number) {
    this.delay = delay;
    this.cache = new Map();
    this.listeners = new Map();
  }
  [Symbol.iterator](): Iterator<DelayCacheUnit<Type>, any, any> {
    return this.cache.values()[Symbol.iterator]() as Iterator<DelayCacheUnit<Type>, any, any>;
  }

  dispose() {
    for(const key of this.cache.keys()) {
      this.delete(key);
    }
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  get(key: string): Type {
    if(!this.cache.has(key)) {
      throw new Error(`Key ${key} not found in cache`);
    }
    return (this.cache.get(key) as DelayCacheUnit<Type>).data;
  }

  set(key: string, data: Type): void {
    if(this.cache.has(key)) {
      (this.cache.get(key) as DelayCacheUnit<Type>).data = data;
      return;
    }
    this.create(key, data);
  }

  create(key: string, data: Type): void {
    if(!this.cache.has(key)) {
      const unit = new DelayCacheUnit(key, this.delay, data);
      this.cache.set(key, unit);
      
      const listener = unit.delayArrived.addListener(new Listener(() => {
        this.delete(key);
      }, this));

      this.listeners.set(key, listener);
    }
  }

  delete(key: string) {
    if(!this.cache.has(key)) {
      throw new Error(`Key ${key} not found in cache`);
    }
    const unit = this.cache.get(key) as DelayCacheUnit<Type>;
    const listener = this.listeners.get(key) as Listener;
    unit.delayArrived.removeListener(listener);
    this.cache.delete(key);
    this.listeners.delete(key);
  }
}

export default DelayCache;
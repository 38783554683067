import HttpService from "@common/services/http";
import { Service } from "@universal/lib/application";
import ConfigurationService from "@universal/services/configuration";
import CurrentTenantService from "@universal/services/currentTenant";
import SessionService from "@universal/services/session";

class MockRequesterService extends Service {
  private _get: any;
  private _clear: any;

  constructor() {
    super("mock", ["http", "currentTenant", "configuration", "session"]);
  }
  
  _setHttpContext = () => {
    return Promise.resolve()
      .then(() => {
        const opt = {
          url: this.application.getService<ConfigurationService>("configuration").get("api_uri_test_mock"),
          headers: {} as { Authorization?: string, tenant?: string }
        };
        
        const token = this.application.getService<SessionService>("session").token;        
        if(token){
          opt.headers.Authorization = 'Bearer ' + token;
          if(this.application.hasService("currentTenant") && this.application.getService<CurrentTenantService>("currentTenant").isSelected()){
            opt.headers.tenant = this.application.getService<CurrentTenantService>("currentTenant").currentId as string;
          }
        }
        return opt;
      });
  }

  start() {
    return this.waitReady(["http"]).then((services) => {
      const [HttpRequest] = services as [HttpService];
      this._get = HttpRequest.create(this._setHttpContext, "/").method("GET").type("application/json").build();
      this._clear = HttpRequest.create(this._setHttpContext, "/").method("DELETE").type("application/json").build();
    });
  }

  async get(){
    return await this._get.execute();
  }

  async clear(){
    return await this._clear.execute();
  }
}

export default MockRequesterService;
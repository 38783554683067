// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-test-step-content {
  display: flex;
  flex-direction: row;
  gap: var(--std-size-inter-element);
}

.bs-test-step-error {
  padding-left: 25px;
  color: var(--std-color-red);
} `, "",{"version":3,"sources":["webpack://./src/views/settings/tools/test/components/step.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".bs-test-step-content {\n  display: flex;\n  flex-direction: row;\n  gap: var(--std-size-inter-element);\n}\n\n.bs-test-step-error {\n  padding-left: 25px;\n  color: var(--std-color-red);\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

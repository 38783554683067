import React from "react";
import Form from "@uBehaviour/form/simple";
import FormContent from "./form/content";
import useService from "@universal/behaviour/hooks/useService";
import ApiService from "@universal/services/api";
import AclService from "@universal/services/acl";
import SessionService from "@universal/services/session";
import CError from '@common/components/error';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import MessageService from "@common/services/message";
import I18nService from "@universal/services/i18n";
import Category from "@universal/types/business/Category";
import Tenant from "@universal/types/business/Tenant";
import RepositoryService from "@universal/services/repository";
import './form.css';


interface IssueFormProps {

}
const IssueValidatorSchema = {
  description: {
    type:"string",
    required:true,
    empty: false
  },
  category:{
    type: "string",
    required: true
  },
  requestor: {
    type: "object",
    properties: {
      firstname: { type: "string", optional: false },
      lastname: { type: "string", optional: false },
      email: { type: "string", optional: true },
      number: { type: "string", optional: true }
      
    }
  }
}

const formValidator = {
  schema: IssueValidatorSchema,
  rules:[
    (value) => value.discriminator === "publicSpace" && (!value.location?.address?.locality || !value.location?.position) ? { path:"location", error:"issue_form_mandatory_location" } : null,
    (value) => value.discriminator === "building" && !value.location?.building ? { path:"location", error:"issue_form_mandatory_building" } : null,
    (value) => value.discriminator === "equipment" && !value.equipment ? { path:"equipment", error:"issue_form_mandatory_equipment" } : null
  ]
}

const IssueForm = React.forwardRef<Form, IssueFormProps>(({}, ref) => {
  const apiService = useService<ApiService>("api");
  const aclService = useService<AclService>("acl");
  const sessionService = useService<SessionService>("session");
  const message = useService<MessageService>("message");  
  const i18n = useService<I18nService>("i18n");    
  const repositoryService = useService<RepositoryService>("repository");



  const checkRedirect = React.useCallback(async (issue, sentValue) => {
    
      if (issue.category !== sentValue.category) {

        const categoryRepository = repositoryService.get("Category");
        
        const [newCategory] = await categoryRepository.repository.find({ _id : issue.category }, {}, 0, 1, { tenant: 1 });
        message.send("success", i18n.translate("issue_creation_successfull_on_client_tenant_redirect", {
          tenantName : i18n.translate(newCategory.tenant.name),
          bsId : issue.bsid,
          categoryName : i18n.translate(newCategory.label)
        }))    
      
    }
  },[repositoryService, message, i18n]);

  const submitNewIssue = React.useCallback(async (form, value) => {
    const issue = await apiService.service("issues", "post").execute(value)
    if (issue) {
      checkRedirect(issue, value);
      return issue;
    }
  }, [apiService, checkRedirect]);
    

  const defaultIssue = React.useMemo(() => {
    const defaultIssue = {
      requestor: {
        type: "userPro",
        firstname: sessionService.user.firstname,
        lastname: sessionService.user.lastname,
        email: sessionService.user.email
      },
      files: [],
      description: "",
      discriminator: null
    };

    if(aclService.connectedUserIsAllow("issues", "createOnPublicSpace")){
      defaultIssue.discriminator = "publicSpace";
    } else if(aclService.connectedUserIsAllow("issues", "createOnBuilding")){
      defaultIssue.discriminator = "building";
    } else if(aclService.connectedUserIsAllow("issues", "createOnEquipment")){
      defaultIssue.discriminator = "equipment";
    } else {
      throw new Error("Can't create");
    }

    return defaultIssue;
  }, [aclService])

  return (
    <Form ref={ ref } submit={ submitNewIssue } default={ defaultIssue } onChange={ FormContent.onChange } validator={formValidator}>
    {(ctx, value, errors, submit) => (
      <div className="bs-issue-newEntry-form-container">
        <ScrollBar viewPortClassName='bs-issue-newEntry-form-content'>
          <FormContent value={ value } />
        </ScrollBar>
        <CError errors={errors.global} />
      </div>
    )}
    </Form>
  )
});

export default IssueForm;